/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f4000Actions from "../../actions/w18f4000-actions";
import * as userActions from "../../actions/user-actions";
import Config from "../../config";
import {Row, Col, FormGroup, Radio} from "react-bootstrap";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import DropDownReportTemplate from "../common/dropdown/template/dropdown-report-template";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import EventTracking from "../common/tracking/event";
import Combo from '../common/dropdown/combo';
import {Button, CheckBox, DateBox} from 'devextreme-react';
import {Column} from 'devextreme-react/data-grid';
import {
    Validator,
    CompareRule,
} from 'devextreme-react/validator';
import ButtonExcel from "../common/button/button-excel";
import ButtonPDF from "../common/button/button-pdf";
import ButtonCustom from "../common/button/button-custom";

import moment from "moment";
import 'moment/locale/vi'
import PassParamsExportReport from "../../actions/async/pass-params-export-report-async";
moment.locale('vi');

const cssLable = {lineHeight: '31px'};

class W18F4000Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            reportTypeID: 'W18F4000',
            isDetail: false,
            isGeneral: true,
            status: '',
            taskDateFormB: '',
            taskDateFormE: '',
            taskDateToB: '',
            taskDateToE: '',
            finishDateB: '',
            finishDateE: '',
            exportType: '',
            reportID: '',
            salesPersonID: '',
            groupSalesID: '',
            taskType: '',
            modeReport: '',
            resetComboPS: false,
            resetComboRT: false,
            iPreview: 12,
            showPreview: false,
            urlExcel: '',
            totalNewCustomer: false,
            totalQuotation: false,
            totalStartDate: null,
            totalFinishDate: null,
            iPermission:null
        };
        this.dataStatus = [];
        this.dataTaskType = [];

    }

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadCombo();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F4000",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
        if (this.timerFocus) clearTimeout(this.timerFocus);
    }

    onRadio() {
        if (this.state.isDetail === true)
            this.setState({
                isDetail: false,
                isGeneral: true,
                reportTypeID: 'W18F4000',
                resetComboRT: true,
                reportID: '',
            });
        else
            this.setState({
                isDetail: true,
                isGeneral: false,
                reportTypeID: 'W18F4001',
                resetComboRT: true,
                reportID: '',
                totalNewCustomer: false,
                totalQuotation: false,
                totalStartDate: null,
                totalFinishDate: null,
            });
    }

    loadCombo() {
        this.props.w18f4000Actions.cboTaskType({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            else if (data && data.length > 0 && data[0].length > 0) {
                this.dataTaskType = data[0].map(o => {
                    const item = {
                        ID: o.ID
                        , Name: o.Name
                    };
                    return item;
                });
            }
            else {
                this.dataTaskType = [];
            }
        });

        const sLanguage = Config.language || '84';
        const mParam = {
            "sLanguage": sLanguage
        };
        this.props.w18f4000Actions.cboStatus(mParam, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            else if (data && data.length > 0 && data[0] && data[0].length > 0) {
                this.dataStatus = data[0].map(o => {
                    const item = {
                        ID: o.ID
                        , Name: o.Name
                    };
                    return item;
                });
            } else {
                this.dataStatus = [];
            }
        });
    }

    onChangeComboGS(data) {
        // console.log("==============onChangeComboGS==============",data);
        if (data) {
            this.setState({groupSalesID: data, salesPersonID: '', resetComboPS: true});
        } else {
            this.setState({groupSalesID: '', salesPersonID: '', resetComboPS: true});
        }
    }

    onChangeComboSP(data) {
        // console.log("==============onChangeComboSP==============",data);
        if (data) {
            this.setState({salesPersonID: data.SalesPersonID, resetComboPS: false});
        } else {
            this.setState({salesPersonID: '', resetComboPS: false});
        }

    }

    onChangeComboTT(data) {
        if (data) {
            this.setState({taskType: data.ID});
        } else {
            this.setState({taskType: ''});
        }

    }

    onChangeComboStatus(data) {
        if (data) {
            this.setState({status: data.ID});
        } else {
            this.setState({status: ''});
        }

    }

    onChangeReportTemplate(data) {
        this.setState({reportID: data.ReportID, resetComboRT: false});
    }

    onEventExcel() {
        this.setState({exportType: 'X'}, () => {
            this.onFormSubmit();
        });
    }

    onEventPreview() {
        this.setState({exportType: 'Preview'}, () => {
            this.onFormSubmit(true);
        });

    }

    onEventPDF() {
        this.setState({exportType: 'PDF'},()=>{
            this.onFormSubmit();
        });
    }

    onChangeTimeStart(data) {
        this.setState({taskDateFormB: data.DateFrom, taskDateFormE: data.DateTo});
    }

    onChangeTimeEnd(data) {
        this.setState({taskDateToE: data.DateTo, taskDateToB: data.DateFrom});
    }

    onChangeTimeFinish(data) {
        this.setState({finishDateE: data.DateTo, finishDateB: data.DateFrom});
    }

    onChangeModeReport(data) {
        this.setState({modeReport: data.ID});
    }

    formatDate = (date) => {
        if(!date) return '';
        if (date) {
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            let year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [month, day, year].join('/');
        }
    };

    async getExportExcelURL(isPreview) {
        const uName = Config.profile.UserID || "";
        let url = "";
        let isPDF = '0';
        if(this.state.exportType === 'PDF')  {
            isPDF = '1';
        }

        let data = {
            "token": Config.token.id,
            "isPDF" : isPDF,
            // 'TemplateURL': 'http://apricot.diginet.com.vn/uploads/2019/3/14779c58-5062-405a-a7a9-38df04777504.xlsx',
            "ReportID": this.state.reportID,
            "FormID": "W18F4000",
            "storeName": "W17P4000",
            "storeParam": JSON.stringify([
                {"name": "UserID", "type": "VarChar", "value": uName},
                {"name": "ReportID", "type": "VarChar", "value": this.state.reportID},
                {"name": "Language", "type": "VarChar", "value": "84"},
                {"name": "GroupSalesID", "type": "VarChar", "value": this.state.groupSalesID},
                {"name": "SalesPersonID", "type": "VarChar", "value": this.state.salesPersonID},
                {"name": "TaskDateFromB", "type": "VarChar", "value": this.formatDate(this.state.taskDateFormB)},
                {"name": "TaskDateFromE", "type": "VarChar", "value": this.formatDate(this.state.taskDateFormE)},
                {"name": "TaskDateToB", "type": "VarChar", "value": this.formatDate(this.state.taskDateToB)},
                {"name": "TaskDateToE", "type": "VarChar", "value": this.formatDate(this.state.taskDateToE)},
                {"name": "FinishDateB", "type": "VarChar", "value": this.formatDate(this.state.finishDateB)},
                {"name": "FinishDateE", "type": "VarChar", "value": this.formatDate(this.state.finishDateE)},
                {"name": "ReportTypeID", "type": "VarChar", "value": this.state.reportTypeID},
                {"name": "ModeReport", "type": "VarChar", "value": this.state.modeReport},
                {"name": "TaskType", "type": "VarChar", "value": this.state.taskType},
                {"name": "TaskStatus", "type": "VarChar", "value": this.state.status},
                {"name": "TotalNewCustomer", "type": "TINYINT", "value": this.state.totalNewCustomer ? 1 : 0},
                {"name": "TotalQuotation", "type": "TINYINT", "value": this.state.totalQuotation ? 1 : 0},
                {"name": "TotalStartDate", "type": "VarChar", "value": this.formatDate(this.state.totalStartDate)},
                {"name": "TotalFinishDate", "type": "VarChar", "value": this.formatDate(this.state.totalFinishDate)},
            ])
        };
        if (!isPreview) {
            let param = Object.keys(data).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
            }).join('&');

            url = Config.env.api + "/w18f4000/report-task?" + param;
        } else {
            try {
                const res = await PassParamsExportReport(data);
                if (res) {
                    let param = {
                        ID: res.id || 0,
                        token: Config.token.id,
                    };
                    param = Object.keys(param).map(function (k) {
                        return k + '=' + param[k];
                    }).join('&');
                    url = Config.env.api + "/export/preview-excel-report?" + encodeURIComponent(param);

                    if (Config.env.system) {
                        url = Config.env.system + "/export/forward-preview-excel-report?url=" + url;
                        url = encodeURIComponent(url);
                    }
                }

            } catch (e) {
                // console.log('=== onFormSubmit => e: ', e);
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                if (e.message) {
                    switch (e.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'W17E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default :
                            message = e.message;
                            break;
                    }
                }
                Config.popup.show("INFO", message);

            }
        }

        // console.log('========= data: ', data);
        return url;
    }

    checkStartFinishDate() {
        let wkStart = moment().startOf('week').format();
        let wkEnd = moment().endOf('week').format();

        if (!this.state.totalNewCustomer && !this.state.totalQuotation) {
            this.setState({
                totalStartDate: null,
                totalFinishDate: null,
            })
        } else {
            this.setState({
                totalStartDate: wkStart,
                totalFinishDate: wkEnd,
            })
        }
    }

    checkURL = async (url) => {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {

                    const contentType = response.headers.get("content-type");
                    //Kiem tra response la json? truong hop ko du lieu
                    if (contentType && contentType.includes("application/json")) {
                        return response.json();
                    }
                    //Kiem tra response la file excel? truong hop co du lieu
                    if (contentType.indexOf('vnd.') > 0) {
                        return {url: url};
                    }
                }).then(json => {
                if (json && json.code && json.message && json.message.length > 0) {
                    return reject(json);
                }
                return resolve(json);
            })
                .catch(error => {
                    reject(error);
                });
        });
    };

    onFormSubmit = async (isPreview) => {
        let url = await this.getExportExcelURL(isPreview);
        // console.log('=== getExportExcelURL => url: ', url);
        this.setState({urlExcel: url}, async () => {
            try {
                if (!Config.env.system) {
                    await this.checkURL(isPreview ? decodeURIComponent(url) : url);
                }
                // console.log('=== onFormSubmit => result: ', result);
                if (this.state.reportID !== '') {
                    if (this.state.exportType === 'Preview') {
                        if (this.state.showPreview === false) {
                            this.setState({iPreview: 6, showPreview: true});
                        } else {
                            this.setState({iPreview: 12, showPreview: false});
                        }
                    } else {
                        let el = document.getElementById("linkExportExcel");
                        el.click();
                    }
                }
            } catch (e) {
                // console.log('=== onFormSubmit => e: ', e);
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                if (e.message) {
                    switch (e.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'F4000E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        case 'EXP002':
                            message = Config.lang("CRM_Mau_bao_cao_khong_ton_tai");
                            break;
                        default :
                            message = e.message;
                            break;
                    }
                }
                if (this.state.showPreview === true) {
                    this.setState({iPreview: 12, showPreview: false});
                }
                Config.popup.show("INFO", message);

            }
        });
    };

    render() {
        if(this.state.iPermission <= 0) return null;
        return (
            <div className="page-container" style={{width: '80%', margin: "0px auto"}}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F4000"} label={Config.profile.UserID}/>}

                <div className="lb-cus-title">{Config.lang("CRM_Bao_cao_hoat_dong")}</div>
                <FormGroup>
                    <Row>
                        <Col sm={this.state.iPreview}>
                            <Row>
                                <Col sm={2}/>
                                <Col sm={5}><Radio id={"General"} checked={!this.state.isDetail}
                                                   onChange={(e) => this.onRadio(e)}><b>{Config.lang("CRM_Tong_hop")}</b></Radio></Col>
                                <Col sm={5}><Radio id={"Detail"} checked={this.state.isDetail}
                                                   onChange={(e) => this.onRadio(e)}><b>{Config.lang("CRM_Chi_tiet")}</b></Radio></Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Mau_bao_cao")}<span
                                        style={{color: 'red'}}><b>*</b></span></label>
                                    <DropDownReportTemplate
                                        required={true}
                                        reset={this.state.resetComboRT}
                                        reportTypeID={this.state.reportTypeID}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeReportTemplate(data)}>
                                    </DropDownReportTemplate>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <label
                                        className={'control-label-above'}>{Config.lang("CRM_Nhom_kinh_doanh")}</label>
                                    <DropDownSaleGroup showClearButton={true} placeholder={""} value={""}
                                                       onChange={(e) => {
                                                           this.onChangeComboGS(e)
                                                       }}/>
                                </Col>
                                <Col sm={6}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Loai_cong_viec")}</label>
                                    <Combo
                                        showClearButton={true}
                                        showHeader={false}
                                        dataSource={this.dataTaskType}
                                        placeholder={''}
                                        value={this.taskType}
                                        valueExpr="ID"
                                        displayExpr="Name"
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboTT(data)
                                        }}
                                    >
                                        <Column dataField={'ID'} caption="" width={'150px'} visible={false}
                                                dataType={'string'}/>
                                        <Column dataField={'Name'} caption="" width={'100%'} dataType={'string'}/>
                                    </Combo>
                                </Col>
                                <Col sm={6}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Nhan_vien")}</label>
                                    <DropDownSalePerson showClearButton={true} groupSalesID={this.state.groupSalesID}
                                                        placeholder={""} value={""}
                                                        reset={this.state.resetComboPS} onChange={(val) => {
                                        this.onChangeComboSP(val)
                                    }}/>
                                </Col>
                                <Col sm={6}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Trang_thai")}</label>
                                    <Combo
                                        showClearButton={true}
                                        showHeader={false}
                                        dataSource={this.dataStatus}
                                        placeholder={''}
                                        value={this.state.status}
                                        valueExpr="ID"
                                        displayExpr="Name"
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboStatus(data)
                                        }}
                                    >
                                        <Column dataField={'ID'} caption="" width={'150px'} visible={false}
                                                dataType={'string'}/>
                                        <Column dataField={'Name'} caption="" width={'100%'} dataType={'string'}/>
                                    </Combo>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <label style={cssLable}
                                           className={'control-label-above'}>{Config.lang("CRM_Ngay_bat_dau")}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5} lg={5} md={6}>
                                    <DropDownTypeTime
                                        listType={"W18F4000_TypeTime"}
                                        placeholder={""}
                                        value={''}
                                        onChange={(data) => this.onChangeTimeStart(data)}
                                    >

                                    </DropDownTypeTime>
                                </Col>

                                <Col sm={7} md={6} lg={7}>
                                    <Row>
                                        <Col sm={6} md={6} lg={6}>
                                            <DateBox
                                                width={"100%"}
                                                id={'TaskDateFormB'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.taskDateFormB}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        taskDateFormB: e.value
                                                    })
                                                }}
                                            />
                                        </Col>
                                        <Col lg={6} sm={6} md={6}>
                                            <DateBox
                                                width={"100%"}
                                                id={'TaskDateFormE'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.taskDateFormE}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        taskDateFormE: e.value
                                                    })
                                                }}

                                            >
                                                <Validator>
                                                    <CompareRule message={Config.lang("CRM_Gia_tri_khong_hop_le")}
                                                                 reevaluate={false}
                                                                 comparisonType={'>='} comparisonTarget={() => {
                                                        return this.state.taskDateFormB
                                                    }}/>
                                                </Validator>
                                            </DateBox>

                                        </Col>

                                    </Row>


                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <label style={cssLable}
                                           className={'control-label-above'}>{Config.lang("CRM_Han_xu_ly")}</label>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={5} lg={5} md={6}>
                                    <DropDownTypeTime
                                        listType={"W18F4000_TypeTime"}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeTimeEnd(data)}
                                        onLoad={(data) => this.onChangeTimeEnd(data)}
                                    >

                                    </DropDownTypeTime>
                                </Col>
                                <Col lg={7} sm={7} md={6}>
                                    <Row>
                                        <Col sm={6} lg={6} md={6}>
                                            <DateBox
                                                width={"100%"}
                                                id={'TaskDateToB'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.taskDateToB}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        taskDateToB: e.value
                                                    })
                                                }}
                                            >
                                            </DateBox>
                                        </Col>

                                        <Col sm={6} lg={6} md={6}>
                                            <DateBox
                                                width={"100%"}
                                                id={'TaskDateToE'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.taskDateToE}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        taskDateToE: e.value
                                                    })
                                                }}
                                            >
                                                <Validator>
                                                    <CompareRule message={Config.lang("CRM_Gia_tri_khong_hop_le")}
                                                                 reevaluate={false}
                                                                 comparisonType={'>='} comparisonTarget={() => {
                                                        return this.state.taskDateToB
                                                    }}/>
                                                </Validator>
                                            </DateBox>

                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <label style={cssLable}
                                           className={'control-label-above'}>{Config.lang("CRM_Ngay_hoan_thanh")}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5} lg={5} md={6}>
                                    <DropDownTypeTime
                                        listType={"W18F4000_TypeTime"}
                                        placeholder={""}
                                        value={''}
                                        onChange={(data) => this.onChangeTimeFinish(data)}
                                    >

                                    </DropDownTypeTime>
                                </Col>
                                <Col lg={7} sm={7} md={6}>
                                    <Row>
                                        <Col sm={6} lg={6} md={6}>
                                            <DateBox
                                                width={"100%"}
                                                id={'FinishDateB'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.finishDateB}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        finishDateB: e.value
                                                    })
                                                }}
                                            />
                                        </Col>

                                        <Col sm={6} lg={6} md={6}>
                                            <DateBox
                                                width={"100%"}
                                                id={'FinishDateE'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.finishDateE}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        finishDateE: e.value
                                                    })
                                                }}
                                            >
                                                <Validator>
                                                    <CompareRule message={Config.lang("CRM_Gia_tri_khong_hop_le")}
                                                                 reevaluate={false}
                                                                 comparisonType={'>='} comparisonTarget={() => {
                                                        return this.state.finishDateB
                                                    }}/>
                                                </Validator>
                                            </DateBox>

                                        </Col>
                                    </Row>


                                </Col>


                            </Row>

                            {this.state.isDetail && <>
                            <Row>
                                <Col sm={12}>
                                    <label style={cssLable}
                                           className={'control-label-above'}>{Config.lang("CRM_Loai_bao_cao")}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5} lg={5} md={6}>
                                    <DropDownTypeTime
                                        listType={"W17P4000_ModeReport"}
                                        placeholder={""}
                                        value={''}
                                        onChange={(data) => this.onChangeModeReport(data)}
                                    />
                                </Col>

                            </Row>
                            </>}

                            <Row style={{marginTop: '35px'}}>
                                <Col sm={12} lg={5} md={6} style={{marginBottom: '10px'}}>
                                    <CheckBox
                                        width={200}
                                        style={{marginTop: '5px'}}
                                        id={'totalNewCustomerW18F100'}
                                        text={Config.lang("CRM_Tong_hop_khach_hang_moi")}
                                        visible={!this.state.isDetail}
                                        value={this.state.totalNewCustomer}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                totalNewCustomer: e.value
                                            });
                                            this.checkStartFinishDate();

                                        }}

                                    />
                                    <CheckBox
                                        width={180}
                                        style={{marginTop: '5px'}}
                                        id={'totalQuotationW18F100'}
                                        value={this.state.totalQuotation}
                                        visible={!this.state.isDetail}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                totalQuotation: e.value
                                            });
                                            this.checkStartFinishDate();

                                        }}
                                        text={Config.lang("CRM_Tong_so_luong_bao_gia")}

                                    />
                                </Col>
                                <div
                                    className={(!this.state.totalNewCustomer && !this.state.totalQuotation) ? 'hide' : ''}>
                                    <Col sm={12} lg={7} md={6}>
                                        <Row>
                                            <Col sm={6} lg={6} md={6}>
                                                <DateBox
                                                    width={"100%"}
                                                    id={'totalStartDate'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    visible={!this.state.isDetail}
                                                    value={this.state.totalStartDate}
                                                    // value={wkStart}
                                                    type={'date'}
                                                    disabled={!this.state.totalNewCustomer && !this.state.totalQuotation}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={false}
                                                    displayFormat={'dd/MM/y'}
                                                    onValueChanged={(e) => {
                                                        this.setState({
                                                            totalStartDate: e.value
                                                        })
                                                    }}
                                                />
                                            </Col>

                                            <Col sm={6} lg={6} md={6}>
                                                <DateBox
                                                    width={"100%"}
                                                    id={'totalFinishDate'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    value={this.state.totalFinishDate}
                                                    // value={wkEnd}
                                                    visible={!this.state.isDetail}
                                                    disabled={!this.state.totalNewCustomer && !this.state.totalQuotation}
                                                    type={'date'}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={false}
                                                    displayFormat={'dd/MM/y'}
                                                    onValueChanged={(e) => {
                                                        this.setState({
                                                            totalFinishDate: e.value
                                                        })
                                                    }}
                                                >
                                                </DateBox>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Row>

                            {/*<i class="far fa-search-plus"></i>*/}
                            <FormGroup style={{marginTop: '15px'}}>
                                <Row>
                                    <Col sm={12}>

                                        <ButtonExcel
                                            onClick={() => this.onEventExcel()} className={"mgr5"}
                                            name={Config.lang("CRM_Xuat_Excel")}/>
                                        <ButtonPDF
                                            onClick={() => this.onEventPDF()} className={"mgr5 hide"}
                                            name={Config.lang("CRM_Xuat_PDF")}/>
                                        <ButtonCustom icon={"far fa-search-plus"} isAwesome={true} color={"text-blue"}
                                                      onClick={() => this.onEventPreview()} className={"mgr5"}
                                                      name={Config.lang("CRM_Xem_Truoc")}/>

                                        <Button id="btnSubmit" onClick={(e) => this.onFormSubmit(e)}
                                                useSubmitBehavior={true} className="hide"/>
                                        <a id="linkExportExcel" className="hide" href={this.state.urlExcel}>View File</a>
                                    </Col>
                                </Row>
                            </FormGroup>

                        </Col>
                        {this.state.showPreview &&
                        <Col sm={6}>
                            <iframe title={"view report"} frameBorder="0" style={{width: '100%', height: '800px'}}
                                    src={'https://view.officeapps.live.com/op/embed.aspx?src=' + this.state.urlExcel}/>

                        </Col>}

                    </Row>
                </FormGroup>
            </div>
        )
    }
}

export default connect(state => ({
        dataW18F4000: state.w18f4000.dataW18F4000,
        cboTaskType: state.w18f4000.cboTaskType,
        cboStatus: state.w18f4000.cboStatus,
    }),
    (dispatch) => ({
        w18f4000Actions: bindActionCreators(w18f4000Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F4000Page);
