/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 12/11/2019
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from "../../config";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import {DateBox, Validator} from 'devextreme-react';
import {Column} from 'devextreme-react/data-grid';
import * as action from "../../actions/w18/w18F4070/w18F4070-actions";
import PageContainer from "../common/page-container/page-container";
import ButtonView from "../common/button/button-view";
import {TreeList} from "devextreme-react/tree-list";
import PropTypes from "prop-types";
import moment from "moment";
import {CustomRule} from "devextreme-react/validator";
import DropDownArea          from '../common/dropdown/template/dropdown-area';
import DropDownDepartment    from '../common/dropdown/template/dropdown-department';
import DropDownObject        from '../common/dropdown/template/dropdown-object';
import DropDownSalePersonWin from '../common/dropdown/template/dropdown-sale-person-win';
import DropDownDivision      from '../common/dropdown/template/dropdown-division';
import ButtonExport          from "../common/button/button-export";
import * as userActions      from "../../actions/user-actions";
import {Loading}             from "../common/loading/loading";
import _                     from "../W17/W17F2035";

export class Container extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateTo: null,
            dateDebt: moment().format("YYYY-MM-DD"),
            customer: '', // gắn giá trị lên dropdown
            dataSource: [],
            time: null,
            objectID: '',
            methodID: '',
            salesOfficeID: '',
            salesGroupID: '',
            division: '',
            loading: false
        };
        // khi click xem lấy các biến này trên master
        this.filter = {
            salesPersonID: '',
            objectID: '',
            type: 'SalesOffice',
            province: ''
        };
        this.treeRef = null;
        this.grid = {};// param truyền vào store
        this.isValidDate = true;
        this.initDefault = false;
    }

    onChangeTime = (data) => {
        const {DateFrom, DateTo} = data;
        let dateF = DateFrom ? moment(DateFrom).format('YYYY-MM-DD') : null;
        let dateT = DateTo ? moment(DateTo).format('YYYY-MM-DD') : null;
        this.setState({dateFrom: dateF, dateTo: dateT});
    };

    changeArea = (e) => {
        if (!this.filter.salesPersonID && !this.state.salesGroupID) this.filter.type = 'SalesOffice';
        this.setState({
            salesOfficeID: e ? e.SalesOfficeID : null,
        });
    };

    onChangeObject = (e) => {
        this.filter.objectID = e;
    };

    onChangeDepartment = (e) => {
        this.setState({
            salesGroupID: e ? e.SalesGroupID : null

        });

        if (!this.filter.salesPersonID && e && e.SalesGroupID) {
            this.filter.type = 'SalesGroup';
        } else if (this.filter.salesPersonID) {
            this.filter.type = 'SalesPerson'
        } else {
            this.filter.type = 'SalesOffice'
        }

    };

    onChangeSalesPerson = (e) => {
        if (e && e.SalesPersonID) {
            this.filter.type = 'SalesPerson';
        } else if (this.state.salesGroupID) {
            this.filter.type = 'SalesGroup';
        } else {
            this.filter.type = 'SalesOffice';
        }
        this.filter.salesPersonID = e ? e.SalesPersonID : null;
    };

    onChangeDateFrom = (e) => {
        this.setState({
            dateFrom: e.value
        })
    };

    onChangeDateTo = (e) => {
        this.setState({
            dateTo: e.value
        })
    };
    loadGrid = (isRefresh) => {
        this.setState({
            loading: true
        });
        const param = {
            SalesOfficeID: isRefresh ? this.state.salesOfficeID : this.grid.salesOfficeID,
            SalesGroupID: isRefresh ? this.state.salesGroupID : this.grid.salesGroupID,
            SalesPersonID: isRefresh ? this.filter.salesPersonID : this.grid.salesPersonID,
            Province: isRefresh ? this.filter.province : this.grid.province,
            ObjectID: isRefresh ? this.filter.objectID : this.grid.objectID,
            DateFrom: this.state.dateFrom,
            DateTo: this.state.dateTo,
            DivisionID: isRefresh ? this.state.division : this.grid.division,
            Mode: isRefresh ? 0 : 1,
            Language: Config.language || '84',
            Type: isRefresh ? this.filter.type : this.grid.type,
        };
        if (isRefresh) {
            const data = this.treeRef.instance.getVisibleRows();
            data.forEach((value) => {
                if (value.isExpanded) this.treeRef.instance.collapseRow(value.key)
            })
        }
        action.getData(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                data.forEach((i, idx) => {
                    if (!isRefresh) {
                        const temp = this.state.dataSource.find(j => {
                            return i.ObjectID === j.ObjectID;
                        });
                        // data[idx].ObjectParentID = data[idx].ObjectParentID || '';
                        // data[idx].ObjectID = data[idx].ObjectID || '';
                        data[idx].ObjectIDTemp = temp ? (data[idx].ObjectID + data[idx].ObjectParentID) : data[idx].ObjectID;
                        const temp2            = this.state.dataSource.find(k => {
                            return data[idx].ObjectIDTemp === k.ObjectIDTemp;
                        });
                        data[idx].ObjectIDTemp = temp2 ? (data[idx].ObjectIDTemp + data[idx].ObjectParentID) : data[idx].ObjectIDTemp;
                    } else {
                        data[idx].ObjectIDTemp = i.ObjectID;
                    }
                });
                this.setState({
                    loading:    false,
                    dataSource: !isRefresh ? [...this.state.dataSource, ...data] : data
                })
            }
        })
    };

    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, "D17F4071");
    }

    componentDidMount() {
        this.loadPermission();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //sau khi default dòng đầu tiên của dropdown salesgroup thì load lại form
        const {dateFrom, dateTo} = this.state;
        if (!this.initDefault && dateFrom && dateTo) {
            this.loadGrid(true);
            this.initDefault = true;
        }
    }

    onView = () => {
        this.grid.objectID = this.filter.objectID;
        this.grid.division = this.state.division;
        if (!this.isValidDate) return false;
        this.loadGrid(true)
    };

    validateData = (e, compareValue, mode) => {
        e.rule.message = '';

        if (!e.value) {
            e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
            this.isValidDate = false;
            return false;
        }
        if (mode === 1) {
            e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
            this.isValidDate = e.value <= compareValue;
            return e.value <= compareValue;
        } else {
            e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
            this.isValidDate = e.value >= compareValue;
            return e.value >= compareValue;
        }
    };


    onAddChild = (e) => {
        const {column, row} = e;
        const genData = (value) => {
            return value ? value : ''
        };

        if (!(e.component.getNodeByKey(e.value).children.length) && row.data.HasChild && column.dataField === 'ObjectID') {
            this.grid.type = row.data.Type;
            this.customer = e.key;
            const {SalesOfficeID, SalesGroupID, Province} = row.data;
            this.grid.salesOfficeID = genData(SalesOfficeID);
            this.grid.salesGroupID = genData(SalesGroupID);
            this.grid.province = genData(Province);
            this.loadGrid(false)
        }
    };

    onChangeDivision = (e) => {
        //Do paramPrint nằm trong render nên phải setstate để cập nhật
        this.setState({
            division: e
        })
    };

    getTreeRef = e => {
        this.treeRef = e
    };

    getParamPrint = () => {
        const {dateFrom, dateTo, salesOfficeID, salesGroupID} = this.state;
        const data = [
            {"id": "DivisionID", "type": "VarChar", "value": this.state.division},
            {"id": "UserID", "type": "VarChar", "value": Config.profile.UserID},
            {"id": 'Language', type: "Varchar", value: Config.language || '84'},
            {"id": "SalesOfficeID", "type": "VarChar", "value": salesOfficeID  || ''},
            {"id": "SalesGroupID", "type": "VarChar", "value": salesGroupID || ''},
            {"id": "Province", "type": "VarChar", "value": this.filter.province || ''},
            {"id": "SalesPersonID", "type": "VarChar", "value": this.filter.salesPersonID || ''},
            {"id": "ObjectID", "type": "VarChar", "value": this.filter.objectID || ''},
            {"id": "Type", "type": "VarChar", "value": this.filter.type},
            {"id": "DateFrom", "type": "DATETIME", "value": dateFrom},
            {"id": "DateTo", "type": "DATETIME", "value": dateTo},
            {"id": "Mode", "type": "INT", "value": 2},
        ];
        return data;
    };

    render() {
        const {dateFrom, dateTo, dataSource, salesOfficeID, salesGroupID, loading} = this.state;
        const paramPrint = [
            {"id": "DivisionID", "type": "VarChar", "value": this.state.division},
            {"id": "UserID", "type": "VarChar", "value": Config.profile.UserID},
            {id: 'Language', type: "Varchar", value: Config.language || '84'},
            {"id": "SalesOfficeID", "type": "VarChar", "value": ''},
            {"id": "SalesGroupID", "type": "VarChar", "value": ''},
            {"id": "Province", "type": "VarChar", "value": ''},
            {"id": "SalesPersonID", "type": "VarChar", "value": ''},
            {"id": "ObjectID", "type": "VarChar", "value": ''},
            {"id": "Type", "type": "VarChar", "value": 'SalesOffice'},
            {"id": "DateFrom", "type": "DATETIME", "value": dateFrom},
            {"id": "DateTo", "type": "DATETIME", "value": dateTo},
            {"id": "Mode", "type": "INT", "value": 2},
        ];

        return (
            <UI dateFrom={dateFrom}
                dateTo={dateTo}
                getTreeRef={this.getTreeRef}
                salesOfficeID={salesOfficeID}
                salesGroupID={salesGroupID}
                loading={loading}
                dataSource={dataSource}
                changeArea={this.changeArea}
                onAddChild={this.onAddChild}
                onChangeDepartment={this.onChangeDepartment}
                onChangeSalesPerson={this.onChangeSalesPerson}
                onChangeDateFrom={this.onChangeDateFrom}
                onChangeDivision={this.onChangeDivision}
                validateData={this.validateData}
                onView={this.onView}
                onChangeDateTo={this.onChangeDateTo}
                onChangeObject={this.onChangeObject}
                onChangeTime={this.onChangeTime}
                paramPrint={paramPrint}
                getParamPrint={this.getParamPrint}
            />
        )
    }
}

const renderContent = function (e) {
    const data = e.row.data;
    return(
        <div style={{fontWeight: !data.ObjectID ? 'bold' : ''}}>{e.text}</div>
    )
};

const UI = React.memo((props) => {
    //----------------------DECLARE----------------------
    const {
        onChangeTime, dateFrom, dateTo, salesGroupID, changeArea, onChangeObject,
        onChangeDepartment, onChangeDateFrom, onChangeDateTo, onChangeSalesPerson,
        onView, validateData, dataSource, onAddChild, salesOfficeID, paramPrint,
        onChangeDivision, loading, getTreeRef, getParamPrint
    } = props;

    //----------------------ENDDECLARE----------------------

    return (
        <PageContainer title={Config.lang('CRM_Bao_cao_so_lieu_hoat_dong')} tracking={'W18F4070'}>
            {loading && <Loading/>}
            <Form horizontal={true} id={'frmW18F4070'}>
                <FormGroup>
                    <Col lg={6} md={12}>
                        <Row>
                            <Col lg={4} md={4}>
                                <Row>
                                    <Col lg={12}>
                                        <label className={'text-muted'}> {Config.lang('CRM_Thoi_gian')}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <DropDownTypeTime listType={'W18F4070_TypeTime'} onChange={onChangeTime}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} md={4} className={'resDate'}>
                                <Row>
                                    <Col lg={12}>
                                        <label className={'text-muted'}> {Config.lang('CRM_Ngay_tu')}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <DateBox width={'100%'}
                                                 value={dateFrom}
                                                 onValueChanged={onChangeDateFrom}
                                                 dateSerializationFormat={'yyyy-MM-dd'}
                                                 displayFormat={'dd/MM/y'}
                                                 openOnFieldClick={true}
                                                 useMaskBehavior={true}
                                                 showClearButton={true}
                                                 type={'date'}
                                                 pickerType={"calendar"}
                                                 showAnalogClock={false}
                                        >
                                            <Validator>
                                                <CustomRule validationCallback={(e) => validateData(e, dateTo, 1)}/>
                                            </Validator>
                                        </DateBox>
                                    </Col>
                                </Row>

                            </Col>
                            <Col lg={4} md={4} className={'resDate'}>
                                <Row>
                                    <Col lg={12}>
                                        <label className={'text-muted'}> {Config.lang('CRM_Ngay_den')}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <DateBox width={'100%'}
                                                 value={dateTo}
                                                 dateSerializationFormat={'yyyy-MM-dd'}
                                                 onValueChanged={onChangeDateTo}
                                                 displayFormat={'dd/MM/y'}
                                                 openOnFieldClick={true}
                                                 useMaskBehavior={true}
                                                 showClearButton={true}
                                                 type={'date'}
                                                 pickerType={"calendar"}
                                                 showAnalogClock={false}
                                        >
                                            <Validator>
                                                <CustomRule validationCallback={(e) => validateData(e, dateFrom, 2)}/>
                                            </Validator>
                                        </DateBox>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={12} id={'areaCol'}>
                        <Row>
                            <Col lg={12}>
                                <label className={'text-muted'}> {Config.lang('CRM_Don_vi')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <DropDownDivision onChange={onChangeDivision} showClearButton={true}/>
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={12}>
                                <label className={'text-muted'}> {Config.lang('CRM_Khu_vuc')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <DropDownArea onChange={changeArea}
                                              showClearButton={true}
                                              value={salesOfficeID}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={12}>
                                <label className={'text-muted'}> {Config.lang('CRM_Phong_ban')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <DropDownDepartment onChange={onChangeDepartment}
                                                    salesOfficeID={salesOfficeID}
                                                    value={salesGroupID}
                                                    showClearButton={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={12}>
                                <label className={'text-muted'}> {Config.lang('CRM_Nhan_vien_kinh_doanh')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <DropDownSalePersonWin salesGroupID={salesGroupID}
                                                       showClearButton={true}
                                                       onChange={onChangeSalesPerson}/>
                                {/*<DropDownSalePerson onChange={onChangeSalesPerson} showClearButton={true}/>*/}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={12}>
                                <label className={'text-muted'}> {Config.lang('CRM_Doi_tuong')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <DropDownObject onChange={onChangeObject} showClearButton={true}/>
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <ButtonExport name={Config.lang('CRM_Xuat_du_lieu')}
                                          param={paramPrint}
                                          getParamPrint={getParamPrint}
                                          className={'mgr5'}
                                          reportTypeID={'W18F4070'}/>
                            <ButtonView name={Config.lang("CRM_Xem")}
                                        onClick={onView}/>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={12}>
                        <TreeList
                            ref={getTreeRef}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            keyExpr={'ObjectIDTemp'}
                            parentIdExpr={'ObjectParentID'}
                            dataSource={dataSource}
                            onCellClick={onAddChild}
                            hasItemsExpr={'HasChild'}
                            showRowLines={true}
                        >
                            <Column caption={Config.lang('CRM_Ma')}
                                    cellRender={renderContent}
                                    width={120}
                                    dataField={'ObjectID'}/>
                            <Column caption={'Tên'}
                                    cellRender={renderContent}
                                    width={200}
                                    dataField={'ObjectNameU'}/>
                            <Column caption={Config.lang('CRM_Du_no_dau_ky')}
                                    cellRender={renderContent}
                                    dataField={'OpeningDebit'}
                                    format={'#,##0'}
                                    alignment={'right'}
                            />
                            <Column caption={Config.lang('CRM_Du_co_dau_ky')}
                                    cellRender={renderContent}
                                    dataField={'OpeningCredit'}
                                    format={'#,##0'}
                                    alignment={'right'}/>
                            <Column caption={Config.lang('CRM_Doanh_thu')}
                                    cellRender={renderContent}
                                    dataField={'Revenue'}
                                    format={'#,##0'}
                                    alignment={'right'}/>
                            <Column caption={Config.lang('CRM_Thue')}
                                    cellRender={renderContent}
                                    dataField={'CVAT'}
                                    format={'#,##0'}
                                    alignment={'right'}/>
                            <Column caption={'Tiền trả'}
                                    cellRender={renderContent}
                                    dataField={'Payment'}
                                    format={'#,##0'}
                                    alignment={'right'}/>
                            <Column caption={Config.lang('CRM_Du_no_cuoi_ky')}
                                    cellRender={renderContent}
                                    dataField={'ClosingDebit'}
                                    format={'#,##0'}
                                    alignment={'right'}/>
                            <Column caption={Config.lang('CRM_Du_co_cuoi_ky')}
                                    cellRender={renderContent}
                                    dataField={'ClosingCredit'}
                                    format={'#,##0'}
                                    alignment={'right'}/>


                        </TreeList>
                    </Col>
                </FormGroup>
            </Form>
        </PageContainer>
    )
}, (prevProps, nextProps) => {
    /* Trả về true nếu nextProps bằng prevProps, ngược lại trả về false */
    // Không deep compare = JSON.stringnify ảnh hưởng performace - chưa fix

    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});


UI.propTypes = {
    onChangeDateFrom: PropTypes.func,
    onChangeDateTo: PropTypes.func,
    onChangeTime: PropTypes.func,
    onChangeSalesGroup: PropTypes.func,
    onChangeSalesPerson: PropTypes.func,

    salesPerson: PropTypes.string,
    salesGroup: PropTypes.string,
    dateTo: PropTypes.string,
    dateFrom: PropTypes.string,
};

export default connect(
    (state) => ({
        dataDebtMethod: state.w18f4050.dataDebtMethod
    }),
    (dispatch) => ({
        action: bindActionCreators(action, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(Container)
