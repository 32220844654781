/**
 * @copyright 2019 @ DigiNet
 * @author XUANVINH
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {
    FormGroup,
    Form,
    Col,
} from 'react-bootstrap';
import {connect} from "react-redux";
import ButtonSave from "../common/button/button-save";
import PopupView from "../popup/popup-view";

import EventTracking from "../common/tracking/event";
import Switch from "react-switch";
import Config from "../../config";
import * as w19F1001Actions from "../../actions/w19/w19F1001/w19F1001-actions";
import {bindActionCreators} from "redux";
import notify from "devextreme/ui/notify";
import PropTypes from 'prop-types';




class W19F1001_Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state={
            aStatus: false,
            approveNote:'',
            approveUser:'',
            isSaved:false
        };

    }



    onHide(){
        const {onHide}=this.props;
        if(onHide){
            onHide(this.state.isSaved&&this.state);
        }
    }

    toggleButton(e){
        this.setState({ aStatus: e })
    }

    componentDidMount(){
        const {aStatus,approveNote}=this.props;
        this.setState({
            aStatus:aStatus===1?true:!!aStatus,
            approveNote:approveNote
        });

    }

    saveDataPop(){
        const aStatus=this.state.aStatus?'1':'0';
        const approveNotesU=document.getElementById('approveNotesUW19F1001').value;
        const {quotationID}=this.props;
        const param={
            QuotationID:quotationID,
            AStatus:aStatus,
            ApproveNotesU:approveNotesU
        };

        this.props.w19F1001Actions.savePopUp(param,  (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
                if(data.code===200){
                    this.setState({
                        isSaved:true
                    });
                    notify(Config.lang('CRM_Duyet_thanh_cong'), "success", 400);
                }
        });
    }






    render() {
        const {approveNote,aStatus,isSaved}=this.state;
        return (
            <PopupView
                title={"Duyệt báo giá"}
                show={true}
                animation={true}
                onHide={() => this.onHide()}>
                <EventTracking action={"FORM"} label={"W19F1001_Popup"}/>

                <div className="page-container">
                    <Form horizontal={true} id={'W19F1001_Popup'}>

                        <FormGroup className={'mgt5'}>
                            <Col md={2} sm={2} xs={2} lg={2}>
                                <Switch
                                    onChange={(e)=>this.toggleButton(e)}
                                    checked={aStatus}
                                    uncheckedIcon={false}
                                    onColor="#3CBF49"
                                    disabled={aStatus&&isSaved}
                                    className="react-switch"
                                />
                            </Col>
                            <Col md={10} sm={10} xs={10} lg={10}>
                                <textarea disabled={aStatus&&isSaved} id={'approveNotesUW19F1001'} className={'form-control'} rows={5} onChange={(e)=>{
                                    this.setState({
                                        approveNote:e.target.value
                                    });
                                }} value={approveNote}/>
                            </Col>
                        </FormGroup>
                        <FormGroup className={'pull-right'}>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <ButtonSave disabled={aStatus&&isSaved} name={'Lưu'} onClick={() => this.saveDataPop()}/>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </PopupView>
        )
    }
}
W19F1001_Popup.propTypes = {
    approveNote:PropTypes.string,
    aStatus:PropTypes.bool,
    approveUser:PropTypes.string,
    quotationID:PropTypes.string,
};
export default connect(() => ({

    }),
    (dispatch) => ({
        w19F1001Actions: bindActionCreators(w19F1001Actions, dispatch)


    })
)(W19F1001_Popup);