/**
 * @copyright 2020 @ DigiNet
 * @author TaiAu
 * @create 2020/10/26
 * @update 2020/10/26
 */

import { Button, DateBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { CompareRule, Validator } from 'devextreme-react/validator';
import CustomStore from "devextreme/data/custom_store";
import _ from "lodash";
import React from 'react';
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PassParamsExportReport from "../../actions/async/pass-params-export-report-async";
import * as userActions from "../../actions/user-actions";
import * as w18f4080Actions from "../../actions/w18/w18F4080/w18f4080-actions";
import getCurrency from "../../actions/w19/w19F1001/cbocurrency-async";
import Config from "../../config";
import ButtonCustom from "../common/button/button-custom";
import ButtonExcel from "../common/button/button-excel";
import Combo from '../common/dropdown/combo';
import DropDownDivision from '../common/dropdown/template/dropdown-division';
import DropDownReportTemplate from "../common/dropdown/template/dropdown-report-template";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import { Loading } from "../common/loading/loading";
import EventTracking from "../common/tracking/event";

const currencyData = {
    store: new CustomStore({
        key: "CurrencyID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
                "select": 'CurrencyID, CurrencyNameU,ExchangeRate,OriginalDecimal',
                "sort": 'CurrencyID',
            };
            if (loadOptions.filter) {
                param.where = JSON.stringify(
                    {
                        fieldName: 'CurrencyID',
                        operator: 'LIKE',
                        value: loadOptions.filter.filterValue
                    },
                );
            }
            const data = await getCurrency(param);
            return {
                'data': data.rows,
                'totalCount': data.total
            };
        }
        ,
        byKey: async (key) => {
            const param = {
                where: JSON.stringify(
                    {
                        fieldName: 'CurrencyID',
                        operator: 'LIKE',
                        value: key
                    },
                )
            };
            const data = await getCurrency(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        },
    })
};

class W18F4080Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showPreview: false,
            iPreview: 12, // Kích thước column 
            iPermission: 0,
            url: '',
            CurrencyID: '',
            ObjectID: '',
            reportID: '',
            urlExcel: '',
            CustomerID: '',
            exportType: '',
            DivisionID: '',
            reportTypeID: 'W18F4080',
            DateFrom: null,
            DateTo: null,
            dataCboObject: [],
            dataCboCustomer: []
        };
        this.Language = Config.language || "84";
        this.UserID = Config.profile.UserID || "";
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        await this.loadCboObject('object');
        await this.loadCboObject('customer');
    };

    loadPermission = async () => {
        await this.props.userActions.getPermission(this.UserID, "D17F4080", (iPer) => {
            this.setState({ iPermission: iPer });
        });
    }

    handleCboObjectApi = (cboName, data) => {
        const { ObjectID, CustomnerID, dataCboObject, dataCboCustomer } = this.state;
        let ObjectTypeID = '';
        switch (cboName) {
            case "object":
                ObjectTypeID = 'NV';
                if (_.isArray(data)) this.setState({ dataCboObject: data })
                if (!_.isEmpty(ObjectID) && !_.isEmpty(dataCboObject) && !_.isEmpty(data)) {
                    const checkObjectID = data.some(e => e.ObjectID === ObjectID);
                    if (!checkObjectID) this.setState({ ObjectID: '' });// Nếu objectID không có trong data mới load thì set lại rỗng
                }
                break;
            case 'customer':
                ObjectTypeID = 'KH';
                if (_.isArray(data)) this.setState({ dataCboCustomer: data })
                if (!_.isEmpty(CustomnerID) && !_.isEmpty(dataCboCustomer) && !_.isEmpty(data)) {
                    const checkCustomnerID = data.some(e => e.ObjectID === CustomnerID);
                    if (!checkCustomnerID) this.setState({ CustomnerID: '' });// Nếu objectID không có trong data mới load thì set lại rỗng
                }
                break;
            default:
                break;
        }
        return { ObjectTypeID }
    }

    loadCboObject = (cboName = '', DivisionID = '',) => {
        const { ObjectTypeID } = this.handleCboObjectApi(cboName);
        const params = {
            DivisionID,
            ObjectTypeID
        }
        this.setState({ loading: true })
        this.props.w18f4080Actions.getCboObject(params, (error, data) => {
            this.setState({ loading: false })
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && !_.isEmpty(data)) {
                this.handleCboObjectApi(cboName, data);
            }
        });
    }

    onChangeCboObjectCustomer = (cboID, data) => {
        const { ObjectID, CustomerID } = this.state;
        if (data) {
            if (cboID === "ObjectID" && data.ObjectID !== ObjectID) this.setState({ ObjectID: data.ObjectID });
            if (cboID === "CustomerID" && data.ObjectID !== CustomerID) this.setState({ CustomerID: data.ObjectID });
        } else {
            this.setState({ [cboID]: '' });
        }
    }

    onChangeReportTemplate = (data) => {
        this.setState({ reportID: data.ReportID, url: data.URL });
    }

    onEventExcel = () => {
        this.setState({ exportType: 'X' }, () => this.onFormSubmit());
    }

    onEventPreview() {
        this.setState({ exportType: 'Preview' }, () => this.onFormSubmit(true));
    }

    onEventPDF = () => {
        this.setState({ exportType: 'PDF' }, () => this.onFormSubmit());
    }

    onChangeTime = (data) => {
        this.setState({ DateFrom: data.DateFrom, DateTo: data.DateTo });
    }

    formatDate = (date) => {
        let value = null;
        if (!_.isNull(date)) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            value = [month, day, year].join('/');
        } else {
            value = date;
        }
        return value;
    }

    getExportExcelURL = async (isPreview) => {
        const { url: TemplateURL, reportID, DivisionID, ObjectID, CustomerID, CurrencyID, DateFrom, DateTo } = this.state;
        let url = "";
        const StoreParam = [
            { name: "UserID", type: "VarChar", value: this.UserID },
            { name: "ReportID", type: "VarChar", value: reportID },
            { name: "Language", type: "VarChar", value: this.Language },
            { name: "DivisionID", type: "NVarChar", value: DivisionID },
            { name: "ObjectID", type: "VarChar", value: ObjectID },
            { name: "CustomerID", type: "VarChar", value: CustomerID },
            { name: "CurrencyID", type: "VarChar", value: CurrencyID },
            { name: "DateFrom", type: "VarChar", value: this.formatDate(DateFrom) },
            { name: "DateTo", type: "VarChar", value: this.formatDate(DateTo) }
        ];
        let data = {
            "token": Config.token.id,
            "TemplateURL": TemplateURL,
            "StoreName": "W17P4080",
            "ReportFileName": reportID,
            "FormID": "W18F4080",
            "StoreParam": JSON.stringify(StoreParam)
        };
        if (!isPreview) {
            let param = Object.keys(data).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
            }).join('&');
            url = Config.env.api + "/w17/excel-report?" + param;
        } else {
            try {
                const res = await PassParamsExportReport(data);
                if (res) {
                    let param = {
                        ID: res.id || 0,
                        token: Config.token.id,
                    };
                    param = Object.keys(param).map(function (k) {
                        return k + '=' + param[k];
                    }).join('&');
                    url = Config.env.api + "/export/preview-excel-report?" + encodeURIComponent(param);

                    if (Config.env.system) {
                        url = Config.env.system + "/export/forward-preview-excel-report?url=" + url;
                        url = encodeURIComponent(url);
                    }
                }

            } catch (e) {
                // console.log('=== onFormSubmit => e: ', e);
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                if (e.message) {
                    switch (e.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'W17E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default:
                            message = e.message;
                            break;
                    }
                }
                Config.popup.show("INFO", message);
            }
        }
        return url;
    }

    checkURL = async (url) => {
        return new Promise((resolve, reject) => {
            fetch(url).then(response => {
                const contentType = response.headers.get("content-type");
                //Kiem tra response la json? truong hop ko du lieu
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                }
                //Kiem tra response la file excel? truong hop co du lieu
                if (contentType.indexOf('vnd.')) {
                    return { url: url };
                }
            }).then(json => {
                if (json && json.code && json.message && json.message.length > 0) {
                    return reject(json);
                }
                return resolve(json);
            }).catch(error => {
                // console.log('=== error: ', error);
                reject(error);
            });
        });
    };

    onFormSubmit = async (isPreview) => {
        let url = await this.getExportExcelURL(isPreview);
        // console.log('=== getExportExcelURL => url: ', url);
        this.setState({ urlExcel: url }, async () => {
            try {
                if (!Config.env.system) {
                    await this.checkURL(isPreview ? decodeURIComponent(url) : url);
                }
                // console.log('=== onFormSubmit => result: ', result);
                if (this.state.reportID !== '') {
                    if (this.state.exportType === 'Preview') {
                        if (this.state.showPreview === false) {
                            this.setState({ iPreview: 6, showPreview: true });
                        } else {
                            this.setState({ iPreview: 12, showPreview: false });
                        }
                    } else {
                        let el = document.getElementById("linkExportExcel");
                        el.click();
                    }
                }
            } catch (e) {
                // console.log('=== onFormSubmit => e: ', e);
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                if (e.message) {
                    switch (e.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'W17E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default:
                            message = e.message;
                            break;
                    }
                }
                if (this.state.showPreview === true) {
                    this.setState({ iPreview: 12, showPreview: false });
                }
                Config.popup.show("INFO", message);
            }
        });
    };

    onChangeDivision = (e) => {
        if (this.state.DivisionID !== e) {
            this.setState({ DivisionID: e || '' }, async () => {
                //Chọn DivisionID xong load lại lại object và customer theo DivisionID
                await this.loadCboObject('object', e);
                await this.loadCboObject('customer', e);
            })
        }
    };

    onChangeCurrency = (data) => {
        this.setState({ CurrencyID: !_.isEmpty(data) ? data.CurrencyID : '' });
    }

    render() {
        const { iPermission, DateFrom, DateTo, dataCboObject, dataCboCustomer, ObjectID, CustomerID, loading, iPreview } = this.state;
        if (iPermission <= 0) return null;
        return (
            <div className="page-container" style={{ width: '80%', margin: "0px auto" }}>
                {Config && Config.profile && <EventTracking action={"W18F4080"} label={Config.profile.UserID} />}
                <div className="lb-cus-title">{Config.lang("CRM_Bao_cao_phan_tich_tien_thu_ban_hang")}</div>
                {loading && <Loading />}
                <FormGroup>
                    <Row>
                        <Col sm={iPreview} xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Mau_bao_cao")}
                                        <span style={{ color: 'red' }}><b>*</b></span></label>
                                    <DropDownReportTemplate
                                        required={true}
                                        reportTypeID={this.state.reportTypeID}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeReportTemplate(data)}>
                                    </DropDownReportTemplate>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Don_vi")}</label>
                                    <DropDownDivision onChange={this.onChangeDivision} showClearButton={true} showIDName={true} />
                                </Col>
                                <Col sm={6} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Nhan_vien_kinh_doanh")}</label>
                                    <Combo
                                        showClearButton={true}
                                        showHeader={false}
                                        dataSource={dataCboObject}
                                        placeholder={``}
                                        value={ObjectID}
                                        disabled={loading}
                                        valueExpr={"ObjectID"}
                                        displayExpr={"ObjectName"}
                                        onActionWhenSelectChange={(data) => this.onChangeCboObjectCustomer("ObjectID", data)}>
                                        <Column
                                            dataField={'ObjectName'}
                                            caption={""}
                                            width={'100%'}
                                            cellRender={(e) => {
                                                const line = !_.isEmpty(e.row.data.ObjectName) ? '-' : '';
                                                return `${e.row.data.ObjectID} ${line} ${e.row.data.ObjectName}`
                                            }}
                                            dataType={'string'} />
                                    </Combo>
                                </Col>
                                <Col sm={6} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Khach_hang")}</label>
                                    <Combo
                                        showClearButton={true}
                                        showHeader={false}
                                        dataSource={dataCboCustomer}
                                        placeholder={''}
                                        value={CustomerID}
                                        disabled={loading}
                                        valueExpr={"ObjectID"}
                                        displayExpr={"ObjectName"}
                                        onActionWhenSelectChange={(data) => this.onChangeCboObjectCustomer("CustomerID", data)}>
                                        <Column
                                            dataField={'ObjectName'}
                                            caption={""}
                                            width={'100%'}
                                            cellRender={(e) => {
                                                const line = !_.isEmpty(e.row.data.ObjectName) ? '-' : '';
                                                return `${e.row.data.ObjectID} ${line} ${e.row.data.ObjectName}`
                                            }}
                                            dataType={'string'} />
                                    </Combo>
                                </Col>
                                <Col sm={6} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Loai_tien")}</label>
                                    <Combo
                                        id={'currencyW19F1001'}
                                        showClearButton={true}
                                        valueExpr={"CurrencyID"}
                                        displayExpr={"CurrencyID"}
                                        dataSource={currencyData}
                                        onActionWhenSelectChange={(data) => this.onChangeCurrency(data)}>
                                        <Column
                                            dataField={'CurrencyID'}
                                            caption={""}
                                            width={'100%'}
                                            dataType={'string'} />
                                    </Combo>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={4} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Ngay_phieu")}</label>
                                    <DropDownTypeTime
                                        listType={"W18F4080_TypeTime"}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeTime(data)}
                                        onLoad={(data) => this.onChangeTime(data)}
                                    >
                                    </DropDownTypeTime>
                                </Col>
                                <Col md={3} sm={4} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Tu_ngay")}</label>
                                    <DateBox
                                        width={"100%"}
                                        id={'DateFrom'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        value={DateFrom}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        onValueChanged={(e) => this.setState({ DateFrom: e.value })}
                                    />
                                </Col>
                                <Col md={3} sm={4} xs={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Den_ngay")}</label>
                                    <DateBox
                                        width={"100%"}
                                        id={'DateTo'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        value={DateTo}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        onValueChanged={(e) => this.setState({ DateTo: e.value })}
                                    >
                                        <Validator>
                                            <CompareRule
                                                message={Config.lang("CRM_Gia_tri_khong_hop_le")}
                                                reevaluate={false}
                                                comparisonType={'>='}
                                                comparisonTarget={() => DateFrom} />
                                        </Validator>
                                    </DateBox>
                                </Col>
                            </Row>
                            <FormGroup style={{ marginTop: '15px' }}>
                                <ButtonExcel
                                    onClick={() => this.onEventPDF()} className={"mgr5 hide"}
                                    name={Config.lang("CRM_Xuat_PDF")} />

                                <ButtonExcel
                                    onClick={() => this.onEventExcel()} className={"mgr5"}
                                    name={Config.lang("CRM_Xuat_Excel")} />

                                <ButtonCustom icon={"far fa-search-plus"} isAwesome={true} color={"text-blue"}
                                    onClick={() => this.onEventPreview()} className={"mgr5"}
                                    name={Config.lang("CRM_Xem_Truoc")} />

                                <Button id="btnSubmit" onClick={(e) => this.onFormSubmit(e)}
                                    useSubmitBehavior={true} className="hide" />

                                <a id="linkExportExcel" className="hide" href={this.state.urlExcel}>View File</a>
                            </FormGroup>
                        </Col>
                        {this.state.showPreview &&
                            <Col sm={6} xs={12}>
                                <iframe title={"view report"} frameBorder="0" style={{ width: '100%', height: '800px' }}
                                    src={'https://view.officeapps.live.com/op/embed.aspx?src=' + this.state.urlExcel} />
                            </Col>}
                    </Row>
                </FormGroup>
            </div>
        )
    }
}

export default connect(state => ({
}), (dispatch) => ({
    w18f4080Actions: bindActionCreators(w18f4080Actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
}))(W18F4080Page);
