/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f4001Actions from "../../actions/w18f4001-actions";
import * as userActions from "../../actions/user-actions";
import PassParamsExportReport from "../../actions/async/pass-params-export-report-async";
import Config from "../../config";
import {Radio} from "react-bootstrap";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import DropDownReportTemplate from "../common/dropdown/template/dropdown-report-template";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import Combo from '../common/dropdown/combo';
import {Button, DateBox} from 'devextreme-react';
import {Column} from 'devextreme-react/data-grid';
import {
    Validator,
    CompareRule,
    RequiredRule
} from 'devextreme-react/validator';
import ButtonExcel from "../common/button/button-excel";
import ButtonCustom from "../common/button/button-custom";
import Select from 'react-select';
import EventTracking from "../common/tracking/event";
import {Row, Col, FormGroup} from 'diginet-core-ui/components';

class W18F4001Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isDetail: false,
            isGeneral: true,
            resetComboPS: false,
            resetComboRT: false,
            showPreview: false,

            reportTypeID: 'W18F4002',
            url: '',
            customerStatusID: '',
            customerLeverID: '',
            reportID: '',
            salesPersonID: '',
            groupSalesID: '',
            fieldGroupID: '',
            createDateFrom: '',
            createDateTo: '',
            exportType: '',
            iPreview: 12,
            iPermission:0,

            urlExcel: '',
            reportDataType: 'CUSTOMER',
            notReOrderFrom: '',
            notReOrderTo: '',
            genderID: null,
            birthDateFrom: null,
            birthDateTo: null,

        };
        this.dataFieldGroup = [];


    }

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadCombo();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F4001",(iPer)=>{
            this.setState({iPermission:iPer});
        });
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
        if (this.timerFocus) clearTimeout(this.timerFocus);
    }

    onRadio() {
        if (this.state.isDetail === true)
            this.setState({
                isDetail: false,
                isGeneral: true,
                reportTypeID: 'W18F4002',
                resetComboRT: true,
                reportID: '',
                fieldGroupID: '',
                reportDataType: 'CUSTOMER',
                notReOrderFrom: '',
                notReOrderTo: '',

            });
        else
            this.setState({
                isDetail: true,
                isGeneral: false,
                reportTypeID: 'W18F4003',
                resetComboRT: true,
                reportID: '',
                reportDataType: 'CUSTOMER'


            });

    }

    loadCombo() {

        this.props.w18f4001Actions.cboCustomerStatus({}, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });

        this.props.w18f4001Actions.cboCustomerLevel({}, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });

        this.props.w18f4001Actions.cboFieldGroup({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.dataFieldGroup = data.map(o => {
                    const item = {
                        value: o.ID
                        , label: o.Name
                    };
                    return item;
                });
            }
        });

        const paramCboGender = {
            Language: Config.language || '',
            FormID: "W18F4001",
            DataType: "ComboGender",
        }
        this.props.w18f4001Actions.getCboGender(paramCboGender, (error) => {
            if (error) {
                const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } 
        });
    }

    onChangeComboGS(data) {
        // console.log("==============onChangeComboGS==============",data);
        if (data) {
            this.setState({groupSalesID: data, salesPersonID: '', resetComboPS: true});
        } else {
            this.setState({groupSalesID: '', salesPersonID: '', resetComboPS: true});
        }
    }

    onChangeComboSP(data) {
        // console.log("==============onChangeComboSP==============",data);
        if (data) {
            this.setState({salesPersonID: data.SalesPersonID, resetComboPS: false});
        } else {
            this.setState({salesPersonID: '', resetComboPS: false});
        }
    }

    onChangeComboCL(data) {
        if (data) {
            this.setState({customerLeverID: data.ID});
        } else {
            this.setState({customerLeverID: ''});
        }
    }

    onChangeReport(data) {
        if (data) {
            if (data.ReportDataTypeID === 'CUSTOMER') {
                this.setState({
                    notReOrderFrom: '',
                    notReOrderTo: '',
                })
            }
            this.setState({reportDataType: data.ReportDataTypeID});
        } else {
            this.setState({reportDataType: ''});
        }
    }

    onChangeComboCS(data) {
        if (data) {
            this.setState({customerStatusID: data.ID});
        } else {
            this.setState({customerStatusID: ''});
        }
    }

    onChangeComboFG(data) {
        let value = '';
        if (data.length > 0) {
            data.map(o => {
                if (value !== '') value += ",";
                value += o.value;
                return o;
            });

        }
        this.setState({fieldGroupID: value});
    }

    onChangeReportTemplate(data) {
        this.setState({reportID: data.ReportID, url: data.URL, resetComboRT: false});
    }

    onEventExcel() {
        const {notReOrderFrom, notReOrderTo, reportDataType} = this.state;
        if (reportDataType === 'NOTREORDER' && (!notReOrderFrom || !notReOrderTo)) {
            Config.popup.show('INFO', Config.lang("CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang"));
            return false;
        }
        this.setState({exportType: 'X'}, () => {
            this.onFormSubmit();
        });
    }

    onEventPreview() {
        const {notReOrderFrom, notReOrderTo, reportDataType} = this.state;
        if (reportDataType === 'NOTREORDER' && (!notReOrderFrom || !notReOrderTo)) {
            Config.popup.show('INFO', Config.lang("CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang"));
            return false;
        }
        this.setState({exportType: 'Preview'}, () => {
            this.onFormSubmit(true);
        });

    }

    onEventPDF() {
        const {notReOrderFrom, notReOrderTo, reportDataType} = this.state;
        if (reportDataType === 'NOTREORDER' && (!notReOrderFrom || !notReOrderTo)) {
            Config.popup.show('INFO', Config.lang("CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang"));
            return false;
        }
        this.setState({exportType: 'PDF'},()=>{
            this.onFormSubmit();
        });
    }

    onChangeTime(data) {
        this.setState({createDateFrom: data.DateFrom, createDateTo: data.DateTo});
    }

    onChangePreOrder(data) {
        this.setState({notReOrderFrom: data.DateFrom, notReOrderTo: data.DateTo});
    }

    onChangeComboGender(data) {
        this.setState({
            genderID: data?.GenderID ?? null
        });
    }

    onChangeBirthDate(data) {
        this.setState({birthDateFrom: data.DateFrom, birthDateTo: data.DateTo});
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [month, day, year].join('/');
    }

    async getExportExcelURL(isPreview) {
        const uName = Config.profile.UserID || "";
        let url = "";
        let data = {
            "token": Config.token.id,
            "TemplateURL": this.state.url,
            "StoreName": "W17P4002",
            "ReportFileName": this.state.reportID,
            "FormID": "W18F4001",

            // "UserID": uName,
            // "ReportID": this.state.reportID,
            // "Language": "84",
            // "GroupSalesID": this.state.groupSalesID,
            // "SalesPersonID": this.state.salesPersonID,
            // "CreateDateFrom": this.formatDate(this.state.createDateFrom),
            // "CreateDateTo": this.formatDate(this.state.createDateTo),
            // "CompanyKindID": this.state.customerLeverID,
            // "CompanyStatus": this.state.customerStatusID,
            // "ReportTypeID": this.state.reportTypeID,
            // "FieldGroup": this.state.fieldGroupID,
            // "ReportTypeData": this.state.reportDataType,
            // "NotReOrderFrom": this.state.notReOrderFrom ? this.formatDate(this.formatDate(this.state.notReOrderFrom)) : '',
            // "NotReOrderTo": this.state.notReOrderTo ? this.formatDate(this.formatDate(this.state.notReOrderTo)) : '',

            "StoreParam": JSON.stringify([
                {"name": "UserID", "type": "VarChar", "value": uName},
                {"name": "ReportID", "type": "VarChar", "value": this.state.reportID},
                {"name": "Language", "type": "VarChar", "value": "84"},
                {"name": "GroupSalesID", "type": "VarChar", "value": this.state.groupSalesID},
                {"name": "SalesPersonID", "type": "VarChar", "value": this.state.salesPersonID},
                {"name": "CreateDateFrom", "type": "VarChar", "value": this.formatDate(this.state.createDateFrom)},
                {"name": "CreateDateTo", "type": "VarChar", "value": this.formatDate(this.state.createDateTo)},
                {"name": "CompanyKindID", "type": "VarChar", "value": this.state.customerLeverID},
                {"name": "CompanyStatus", "type": "VarChar", "value": this.state.customerStatusID},
                {"name": "ReportTypeID", "type": "VarChar", "value": this.state.reportTypeID},
                {"name": "FieldGroup", "type": "VarChar", "value": this.state.fieldGroupID},
                {"name": "ReportTypeData", "type": "VarChar", "value": this.state.reportDataType},
                {
                    "name": "NotReOrderFrom",
                    "type": "VarChar",
                    "value": this.state.notReOrderFrom ? this.formatDate(this.formatDate(this.state.notReOrderFrom)) : ''
                },
                {
                    "name": "NotReOrderTo",
                    "type": "VarChar",
                    "value": this.state.notReOrderTo ? this.formatDate(this.formatDate(this.state.notReOrderTo)) : ''
                },
                {"name": "GenderID", "type": "TINYINT", "value": this.state.genderID},
                {"name": "BirthDateFrom", "type": "VarChar", "value": this.formatDate(this.state.birthDateFrom)},
                {"name": "BirthDateTo", "type": "VarChar", "value": this.formatDate(this.state.birthDateTo)}
            ])
        };

        if (!isPreview) {
            let param = Object.keys(data).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
            }).join('&');

            url = Config.env.api + "/w17/excel-report?" + param;
        } else {
            try {
                const res = await PassParamsExportReport(data);
                if (res) {
                    let param = {
                        ID: res.id || 0,
                        token: Config.token.id,
                    };
                    param = Object.keys(param).map(function (k) {
                        return k + '=' + param[k];
                    }).join('&');
                    url = Config.env.api + "/export/preview-excel-report?" + encodeURIComponent(param);

                    if (Config.env.system) {
                        url = Config.env.system + "/export/forward-preview-excel-report?url=" + url;
                        url = encodeURIComponent(url);
                    }
                }

            } catch (e) {
                // console.log('=== onFormSubmit => e: ', e);
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                if (e.message) {
                    switch (e.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'W17E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default :
                            message = e.message;
                            break;
                    }
                }
                Config.popup.show("INFO", message);

            }
        }

        // let sql = `EXEC W17P4002 '${uName}','${this.state.reportID}',
        //           '${"84"}','${this.state.groupSalesID}',
        //           '${this.state.salesPersonID}',
        //           '${this.formatDate(this.state.createDateFrom)}',
        //           '${this.formatDate(this.state.createDateTo)}',
        //           '${this.state.customerLeverID}',
        //           '${this.state.customerStatusID}',
        //           '${this.state.reportTypeID}',
        //           '${this.state.fieldGroupID}'`;
        //  console.log("=========getExportExcelURL==============",sql);
        return url;
    }

    checkURL = async (url) => {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    //Kiem tra response la json? truong hop ko du lieu
                    if (contentType && contentType.includes("application/json")) {
                        return response.json();
                    }
                    //Kiem tra response la file excel? truong hop co du lieu
                    if (contentType.indexOf('vnd.')) {
                        return {url: url};
                    }
                }).then(json => {
                if (json && json.code && json.message && json.message.length > 0) {
                    return reject(json);
                }
                return resolve(json);
            })
                .catch(error => {
                    // console.log('=== error: ', error);
                    reject(error);
                });
        });
    };

    onFormSubmit = async (isPreview) => {
        let url = await this.getExportExcelURL(isPreview);
        // console.log('=== getExportExcelURL => url: ', url);
        this.setState({urlExcel: url}, async () => {
            try {
                if (!Config.env.system) {
                    await this.checkURL(isPreview ? decodeURIComponent(url) : url);
                }
                // console.log('=== onFormSubmit => result: ', result);
                if (this.state.reportID !== '') {
                    if (this.state.exportType === 'Preview') {
                        if (this.state.showPreview === false) {
                            this.setState({iPreview: 6, showPreview: true});
                        } else {
                            this.setState({iPreview: 12, showPreview: false});
                        }
                    } else {
                        let el = document.getElementById("linkExportExcel");
                        el.click();
                    }
                }
            } catch (e) {
                // console.log('=== onFormSubmit => e: ', e);
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                if (e.message) {
                    switch (e.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'W17E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default :
                            message = e.message;
                            break;
                    }
                }
                if (this.state.showPreview === true) {
                    this.setState({iPreview: 12, showPreview: false});
                }
                Config.popup.show("INFO", message);

            }
        });
    };

    render() {
        if(this.state.iPermission <= 0) return null;
        const {cboCustomerStatus, cboCustomerLevel, dataCboGender} = this.props;

        const dataReportType = [
            {
                ReportDataTypeID: 'CUSTOMER',
                ReportDataTypeName: Config.lang('CRM_Khach_hang')
            },
            {
                ReportDataTypeID: 'CUSTOMERANDCONTACT',
                ReportDataTypeName: Config.lang('CRM_Khach_hang_va_lien_he')
            },
            {
                ReportDataTypeID: 'NOTREORDER',
                ReportDataTypeName: Config.lang('CRM_Khong_tai_ky_dat_hang')
            },
        ];

        return (
            <div className="page-container" style={{width: '80%', margin: "0px auto"}}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F4001"} label={Config.profile.UserID}/>}

                <div className="lb-cus-title">{Config.lang("CRM_Bao_cao_thong_ke_khach_hang")}</div>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={this.state.iPreview}>
                            <Row>
                                <Col xs={0} sm={0} lg={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}/>
                                <Col xs={12} sm={12} lg={5}>
                                    <Row>
                                        <Col xs={12} sm={2} md={2} lg={3}>
                                            <Radio id={"Detail"} checked={this.state.isDetail}
                                                onChange={(e) => this.onRadio(e)}><b>{Config.lang("CRM_Chi_tiet")}</b></Radio>
                                        </Col>
                                        <Col xs={12} sm={10} md={10} lg={6}>
                                            <Combo
                                                onContentReady={(e) => {
                                                    if (!this.state.isDetail) {
                                                        e.component.selectRowsByIndexes([0]);
                                                    }
                                                }}
                                                showHeader={false}
                                                disabled={!this.state.isDetail}
                                                dataSource={dataReportType}
                                                placeholder={''}
                                                value={this.state.reportDataType}
                                                valueExpr="ReportDataTypeID"
                                                displayExpr="ReportDataTypeName"
                                                onActionWhenSelectChange={(data) => {
                                                    this.onChangeReport(data)
                                                }}
                                            >
                                                <Column dataField={'ReportDataTypeID'} caption="" width={'150px'}
                                                        visible={false} dataType={'string'}/>
                                                <Column dataField={'ReportDataTypeName'} caption="" width={'100%'}
                                                        dataType={'string'}/>
                                            </Combo>
                                        </Col>

                                    </Row>


                                </Col>
                                <Col xs={12} sm={12} lg={5}>
                                    <Radio id={"General"} checked={!this.state.isDetail}
                                        onChange={(e) => this.onRadio(e)}><b>{Config.lang("CRM_Tong_hop")}</b></Radio></Col>

                                <Col xs={12} sm={12}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Mau_bao_cao")}<span
                                        style={{color: 'red'}}><b>*</b></span></label>
                                    <DropDownReportTemplate
                                        required={true}
                                        reset={this.state.resetComboRT}
                                        reportTypeID={this.state.reportTypeID}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeReportTemplate(data)}>
                                    </DropDownReportTemplate>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <label
                                        className={'control-label-above'}>{Config.lang("CRM_Nhom_kinh_doanh")}</label>
                                    <DropDownSaleGroup showClearButton={true} placeholder={""} value={""}
                                                       onChange={(e) => {
                                                           this.onChangeComboGS(e)
                                                       }}/>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <label
                                        className={'control-label-above'}>{Config.lang("CRM_Trang_thai_khach_hang")}</label>
                                    <Combo
                                        showClearButton={true}
                                        showHeader={false}
                                        dataSource={cboCustomerStatus}
                                        // dataSource={dataCustomerStatus}
                                        placeholder={''}
                                        value={''}
                                        valueExpr="ID"
                                        displayExpr="Name"
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboCS(data)
                                        }}
                                    >
                                        <Column dataField={'ID'} caption="" width={'150px'} visible={false}
                                                dataType={'string'}/>
                                        <Column dataField={'Name'} caption="" width={'100%'} dataType={'string'}/>
                                    </Combo>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Nhan_vien")}</label>
                                    <DropDownSalePerson showClearButton={true} groupSalesID={this.state.groupSalesID}
                                                        placeholder={""} value={""}
                                                        reset={this.state.resetComboPS} onChange={(val) => {
                                        this.onChangeComboSP(val)
                                    }}/>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <label
                                        className={'control-label-above'}>{Config.lang("CRM_Phan_loai_khach_hang")}</label>
                                    <Combo
                                        showClearButton={true}
                                        showHeader={false}
                                        dataSource={cboCustomerLevel}
                                        // dataSource={dataCustomerLevel}
                                        placeholder={''}
                                        value={''}
                                        valueExpr="ID"
                                        displayExpr="Name"
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboCL(data)
                                        }}
                                    >
                                        <Column dataField={'ID'} caption="" width={'150px'} visible={false}
                                                dataType={'string'}/>
                                        <Column dataField={'Name'} caption="" width={'100%'} dataType={'string'}/>
                                    </Combo>
                                </Col>

                                <Col xs={12} sm={4}>
                                    <label className={'control-label-above'}>{Config.lang("CRM_Ngay_tao")}</label>
                                    <DropDownTypeTime
                                        listType={"W18F1020_TypeTime"}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeTime(data)}
                                        onLoad={(data) => this.onChangeTime(data)}
                                    >

                                    </DropDownTypeTime>
                                </Col>

                                <Col xs={12} sm={4} verticalAlign={"end"}>
                                    <DateBox
                                        width={"100%"}
                                        id={'CreateDateFrom'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        value={this.state.createDateFrom}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                createDateFrom: e.value
                                            })
                                        }}
                                    />
                                </Col>

                                <Col xs={12} sm={4} verticalAlign={"end"}>
                                    <DateBox
                                        width={"100%"}
                                        id={'CreateDateTo'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        value={this.state.createDateTo}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                createDateTo: e.value
                                            })
                                        }}

                                    >
                                        <Validator>
                                            <CompareRule message={Config.lang("CRM_Gia_tri_khong_hop_le")}
                                                         reevaluate={false}
                                                         comparisonType={'>='} comparisonTarget={() => {
                                                return this.state.createDateFrom
                                            }}/>
                                        </Validator>
                                    </DateBox>

                                </Col>
                                {!this.state.isDetail &&
                                    <Col xs={12} sm={12}>
                                        <label className={'control-label-above'}>{Config.lang("CRM_Tieu_thuc_nhom")}</label>
                                        <Select
                                            isMulti
                                            name="colors"
                                            // options={dataFieldGroup}
                                            options={this.dataFieldGroup}
                                            onChange={(data) => this.onChangeComboFG(data)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"

                                        />
                                    </Col>
                                }
                                {this.state.reportDataType === 'NOTREORDER' &&
                                    <>
                                        <Col xs={12} sm={4}>
                                            <label
                                                className={'control-label-above'}>{Config.lang("CRM_Thoi_gian_khong_tai_ky_dat_hang")}</label>
                                            <DropDownTypeTime
                                                listType={"W18F4000_TypeTime"}
                                                placeholder={""}
                                                onChange={(data) => this.onChangePreOrder(data)}
                                                onLoad={(data) => this.onChangePreOrder(data)}
                                            >
                                            </DropDownTypeTime>


                                        </Col>

                                        <Col xs={12} sm={4} verticalAlign={"end"}>
                                            <DateBox
                                                width={"100%"}
                                                id={'CreateDateFrom'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                visible={this.state.reportDataType === 'NOTREORDER'}
                                                value={this.state.notReOrderFrom}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        notReOrderFrom: e.value
                                                    })
                                                }}

                                            >
                                                <Validator>
                                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                                </Validator>
                                            </DateBox>
                                        </Col>

                                        <Col xs={12} sm={4} verticalAlign={"end"}>
                                            <DateBox
                                                width={"100%"}
                                                id={'CreateDateTo'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                visible={this.state.reportDataType === 'NOTREORDER'}
                                                showClearButton={true}
                                                value={this.state.notReOrderTo}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        notReOrderTo: e.value
                                                    })
                                                }}

                                            >

                                                <Validator>
                                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                                </Validator>
                                            </DateBox>

                                        </Col>
                                    </>
                                }
                                <Col xs={12} sm={4}>
                                    <label className={'control-label-above'}>{Config.lang("Gioi_tinh")}</label>
                                    <Combo
                                        showHeader={false}
                                        showClearButton={true}
                                        dataSource={dataCboGender}
                                        placeholder={''}
                                        value={this.state.genderID}
                                        valueExpr={"GenderID"}
                                        displayExpr={"GenderName"}
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboGender(data);
                                        }}
                                    >
                                        <Column dataField={'GenderID'} caption="" visible={false}
                                            dataType={'number'} />
                                        <Column dataField={'GenderName'} caption="" dataType={'string'} />
                                    </Combo>
                                </Col>
                                <Col xs={0} sm={8} sx={{ display: { xs: 'none', sm: 'block' } }} />
                                <Col xs={12} sm={4}>
                                   <label className={'control-label-above'}>{Config.lang("Ky_niem_ngay_thanh_lap")}</label>
                                   <DropDownTypeTime
                                        listType={"W18F1020_TypeTime"}
                                        placeholder={""}
                                        onChange={(data) => this.onChangeBirthDate(data)}
                                        onLoad={(data) => this.onChangeBirthDate(data)}
                                    />
                                </Col>
                                <Col xs={12} sm={4} verticalAlign={"end"}>
                                    <DateBox
                                        width={"100%"}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        value={this.state.birthDateFrom}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                birthDateFrom: e.value
                                            })
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={4} verticalAlign={"end"}>
                                    <DateBox
                                        width={"100%"}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        value={this.state.birthDateTo}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                birthDateTo: e.value
                                            })
                                        }}
                                    >
                                        <Validator>
                                            <CompareRule message={Config.lang("Gia_tri_khong_hop_le")}
                                                reevaluate={false}
                                                comparisonType={'>='} comparisonTarget={() => {
                                                    return this.state.birthDateFrom
                                                }} />
                                        </Validator>
                                    </DateBox>
                                </Col>
                                
                                <Col xs={12} sm={12}>
                                        <ButtonExcel
                                            onClick={() => this.onEventPDF()} className={"mgr5 hide"}
                                            name={Config.lang("CRM_Xuat_PDF")}/>

                                        <ButtonExcel
                                            onClick={() => this.onEventExcel()} className={"mgr5"}
                                            name={Config.lang("CRM_Xuat_Excel")}/>

                                        <ButtonCustom icon={"far fa-search-plus"} isAwesome={true} color={"text-blue"}
                                                      onClick={() => this.onEventPreview()} className={"mgr5"}
                                                      name={Config.lang("CRM_Xem_Truoc")}/>

                                        <Button id="btnSubmit" onClick={(e) => this.onFormSubmit(e)}
                                                useSubmitBehavior={true} className="hide"/>

                                        <a id="linkExportExcel" className="hide" href={this.state.urlExcel}>View File</a>
                                    </Col>
                            </Row>

                        </Col>
                        {this.state.showPreview &&
                        <Col xs={12} sm={12} md={6}>
                            <iframe title={"view report"} frameBorder="0" style={{width: '100%', height: '800px'}}
                                    src={'https://view.officeapps.live.com/op/embed.aspx?src=' + this.state.urlExcel}/>
                        </Col>}

                    </Row>
                </FormGroup>
            </div>
        )
    }
}

export default connect(state => ({
        cboCustomerStatus: state.w18f4001.cboCustomerStatus,
        cboCustomerLevel: state.w18f4001.cboCustomerLevel,
        cboFieldGroup: state.w18f4001.cboFieldGroup,
        dataCboGender: state.w18f4001.dataCboGender
    }),
    (dispatch) => ({
        w18f4001Actions: bindActionCreators(w18f4001Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F4001Page);
