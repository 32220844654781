/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 12/11/2019
 */

import React                                 from 'react';
import {connect}                             from "react-redux";
import {bindActionCreators}                  from "redux";
import Config                                from "../../config";
import {Col, Form, FormGroup, Row, Collapse} from "react-bootstrap";
import DropDownSaleGroup                     from "../common/dropdown/dropdown-sale-group";
import DropDownCustomer                      from "../common/dropdown/dropdown-customer";
import DropDownSalePerson                    from "../common/dropdown/template/dropdown-sale-person";
import DropDownTypeTime                      from "../common/dropdown/template/dropdown-type-time";
import {DateBox, Validator}                  from 'devextreme-react';
import {Column}                              from 'devextreme-react/data-grid';
import * as action                           from "../../actions/w18/w18F4050/w18F4050-actions";
import ButtonView                            from "../common/button/button-view";
import ButtonExport                          from "../common/button/button-export";
import {TreeList} from "devextreme-react/tree-list";
import PropTypes from "prop-types";
import moment from "moment";
import {CustomRule, RequiredRule} from "devextreme-react/validator";
import Combo from "../common/dropdown/combo";
import * as userActions from "../../actions/user-actions";


export class Container extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateTo: null,
            dateDebt: moment().format("YYYY-MM-DD"),
            salesGroup: '',
            salesPerson: '',
            customer: '', // gắn giá trị lên dropdown
            dataSource: [],
            time: null,
            objectID: '',
            methodID: '',
            dynamicCaption: [],
            showFilterCondition: false
        };

        this.type = '';
        this.customer = ''; // không gắn giá lên dropdown
        this.initDefault = false;
        this.allowLoadCap = true; // khi methodid khác methoid cũ thì mới gọi api đổ nguồn caption động
    }

    //----------------------METHOD----------------------

    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, "D17F4050");
    }

    loadDataDebtMethod = () => {
        this.props.action.getDataDebtMethod((error, data) => {
            if (error) return;
            if (data.length) {
                this.setState({
                    methodID: data[0].MethodID
                })
            }
        });
    };


    loadDynamicCaption = () => {
        if (!this.allowLoadCap) return;
        action.getDynamicCaption({MethodID: this.state.methodID}, (error, data) => {
            if (error || !data.length) {
                return false;
            }
            this.setState({
                dynamicCaption: data
            })
        })
    };


    loadGrid = (mode) => {
        //mode = init tao lưới lần đầu, mode ='refresh'  mới ngược lại cộng thêm
        const {
            customer, dateFrom, dateTo, salesPerson,
            salesGroup, dateDebt, methodID
        } = this.state;
        const {type} = this;
        let fObject, fType;
        if (customer) {
            fObject = customer; //su dung this.customer de khogn set gia tri cho dropdown
            fType = 'Company';
        } else if (!customer && salesPerson) {
            fObject = salesPerson;
            fType = 'SalesPerson';
        } else {
            fObject = mode === 'init' ? null : salesGroup;
            fType = 'GroupSales';
        }
        const param = {
            UserID: Config.profile.UserID || "",
            Language: Config.language || '84',
            ObjectID: mode ? fObject : this.customer,
            Type: !mode ? type : fType,
            DateFrom: dateFrom,
            DateTo: dateTo,
            Mode: mode ? 1 : 0,
            MethodID: methodID,
            Date: dateDebt

        };

        action.getTreeData(param, (error, data) => {
            this.setState({
                dataSource: !mode ? [...this.state.dataSource, ...data] : data
            });
            this.allowLoadCap = false;
        })
    };

    onShowFilterCondition = () => {
        this.setState({
            showFilterCondition: !this.state.showFilterCondition
        })
    }

    componentDidMount = () => {
        this.loadPermission();
        this.loadDataDebtMethod();
    };

    setDateFrom = (e) => {
        this.setState({
            dateFrom: e,
        })
    };

    setDateTo = (e) => {
        this.setState({
            dateTo: e,
        })
    };

    setTime = (data) => {
        this.setState({
            time: data.ID,
            dateFrom: data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null,
            dateTo: data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null
        })
    };

    setSalesGroup = (e) => {
        this.setState({
            salesGroup: e,
            salesPerson: '',
            customer: '',
        })
    };

    setCustomer = (e) => {
        this.setState({
            customer: e
        })
    };

    setSalesPerson = (e) => {
        this.setState({
            salesPerson: e,
        })
    };

    setDefaultSalesGroup = (e) => {
        const IsRoot = e.find(value => value.IsRoot === 1);

        this.setState({
            salesGroup: IsRoot.GroupSalesID
        })

    };

    onAddChild = (e) => {
        const {data} = e.row;// su dung bien vi state se render lai form va gan gia tri len dropdown

        if (!(e.component.getNodeByKey(e.value).children.length) && data.HasChild) {
            this.type = data.Type;
            this.customer = e.key;
            this.loadGrid()
        }
    };

    getDecimal = () => {
        this.props.action.getDecimal((error) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('CRM_Loi_chua_xac_dinh'));
                return false;
            }
        })
    };


    onView = () => {
        const {dateFrom, dateTo, dateDebt, methodID} = this.state;
        if (dateFrom && dateTo && dateDebt && methodID) {
            this.loadDynamicCaption();
            this.loadGrid('refresh');
            return
        }
        Config.popup.show('INFO', Config.lang('CRM_Dieu_kien_loc_khong_hop_le'));
    };

    //----------------------ENDMETHOD----------------------

    componentDidUpdate(prevProps, prevState, snapshot) {
        //sau khi default dòng đầu tiên của dropdown salesgroup thì load lại form
        const {salesGroup, dateFrom, dateTo, methodID} = this.state;
        if (!this.initDefault && salesGroup && dateFrom && dateTo && methodID) {
            this.loadDynamicCaption();
            this.loadGrid('init');
            this.initDefault = true;
        }
    }

    onChangeDebtMethod = (e) => {
        let methodID = e ? e.MethodID : null;
        if (e && this.state.methodID !== e.MethodID) this.allowLoadCap = true;
        this.setState({
            methodID: methodID
        })
    };

    onChangeDateDebt = (e) => {
        this.setState({
            dateDebt: e.value
        })
    };

    render() {
        const {dateFrom, dateTo, salesPerson, salesGroup, customer, dataSource, dateDebt, methodID, dynamicCaption, showFilterCondition} = this.state;
        const {dataDebtMethod} = this.props;

        return (
            <UI onChangeDateFrom={this.setDateFrom}
                onChangeDateTo={this.setDateTo}
                onChangeTime={this.setTime}
                onChangeSalesGroup={this.setSalesGroup}
                onInitSalesGroup={this.setDefaultSalesGroup}
                onChangeSalesPerson={this.setSalesPerson}
                onChangeCustomer={this.setCustomer}
                onAddChild={this.onAddChild}
                onView={this.onView}
                onChangeDateDebt={this.onChangeDateDebt}
                onChangeDebtMethod={this.onChangeDebtMethod}
                onShowFilterCondition={this.onShowFilterCondition}

                dynamicCaption={dynamicCaption}
                methodID={methodID}
                dataDebtMethod={dataDebtMethod}
                dateDebt={dateDebt}
                dateFrom={dateFrom}
                dateTo={dateTo}
                salesPerson={salesPerson}
                salesGroup={salesGroup}
                customer={customer}
                dataSource={dataSource}
                showFilterCondition={showFilterCondition}
            />
        )
    }
}

const UI = React.memo((props) => {
    //----------------------DECLARE----------------------
    const paramFilter = {
        onChangeDateFrom: props.onChangeDateFrom,
        onChangeDateTo: props.onChangeDateTo,
        onChangeSalesGroup: props.onChangeSalesGroup,
        onChangeSalesPerson: props.onChangeSalesPerson,
        onChangeCustomer: props.onChangeCustomer,
        onChangeDateDebt: props.onChangeDateDebt,
        salesGroup: props.salesGroup,
        salesPerson: props.salesPerson,
        customer: props.customer,
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
        dateDebt: props.dateDebt,
        dataDebtMethod: props.dataDebtMethod,
        onInitSalesGroup: props.onInitSalesGroup,
        onChangeDebtMethod: props.onChangeDebtMethod,
        methodID: props.methodID,
        onChangeTime: props.onChangeTime,
        onView: props.onView,
        showFilterCondition: props.showFilterCondition

    };

    const paramTree = {
        dataSource: props.dataSource,
        dynamicCaption: props.dynamicCaption,
        onAddChild: props.onAddChild,
        dataDecimal: props.dataDecimal && props.dataDecimal.D07_QuantityDecimals
    };
    //----------------------ENDDECLARE----------------------

    return (
        <div id={'W18F4050'} className="page-container">
            <div className={'mgb5'} style={{display: 'flex', flexWrap: 'wrap'}}>
                <div className="grid-title">{'Thông tin công nợ'}</div>
                <div className={'mgr5'} style={{marginTop: '-3px', marginLeft: 'auto', cursor: 'pointer'}}>
                    <i style={{
                        transition: 'transform .5s',
                        color: `${props.showFilterCondition ? 'rgb(66, 148, 251)' : ''}`,
                        transform: `${props.showFilterCondition ? 'rotate(177deg)' : ''}`
                    }} onClick={props.onShowFilterCondition}
                        className={'fa-lg fas fa-chevron-circle-down mgt10'}
                    />
                </div>
            </div>
            <Form horizontal={true}>
                <Collapse in={props.showFilterCondition}>
                    <div>
                        <FilterCondition {...paramFilter}  />
                    </div>
                </Collapse>
                <FormGroup>
                    <Col lg={12}>
                        <TreeListComp {...paramTree}/>
                    </Col>
                </FormGroup>
            </Form>
        </div>
    )
}, (prevProps, nextProps) => {
    /* Trả về true nếu nextProps bằng prevProps, ngược lại trả về false */
    // Không deep compare = JSON.stringnify ảnh hưởng performace - chưa fix

    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});


const FilterCondition = React.memo((props) => {
    //----------------------DECLARE----------------------
    // const contextValue = useContext(Context) // ;
    const {
        salesGroup, salesPerson, customer, dateFrom, dateTo,
        dateDebt, dataDebtMethod, onChangeDebtMethod, methodID, onView, onChangeDateDebt
    } = props;
    //----------------------ENDDECLARE----------------------

    //----------------------METHOD----------------------

    const onChangeDateFrom = (e) => {
        props.onChangeDateFrom && props.onChangeDateFrom(e.value)
    };

    const onChangeDateTo = (e) => {
        props.onChangeDateTo && props.onChangeDateTo(e.value)
    };

    const onChangeSalesGroup = (e) => {
        props.onChangeSalesGroup && props.onChangeSalesGroup(e)
    };

    const onChangeTime = (data, mode) => {
        props.onChangeTime && props.onChangeTime(data, mode);
    };

    const onChangeSalesPerson = (e) => {
        props.onChangeSalesPerson && props.onChangeSalesPerson(e && e.SalesPersonID);
    };

    const onChangeCustomer = (e) => {
        props.onChangeCustomer && props.onChangeCustomer(e);
    };

    const _onInitSalesGroup = (e) => {
        props.onInitSalesGroup && props.onInitSalesGroup(e);
    };

    const validateData = (e, compareValue, mode) => {
        e.rule.message = '';
        if (!e.value) {
            e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
            return false;
        }
        if (mode === 1) {
            e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
            return e.value <= compareValue;
        } else {
            e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
            return e.value >= compareValue;
        }

    };


    //----------------------ENDMETHOD----------------------

    const paramPrint = [
        {type: "Varchar", id: "UserID", value: Config.profile.UserID},
        {type: "Varchar", id: "Language", value: Config.language || '84'},
        {type: "Varchar", id: "ObjectID", value: customer || salesPerson || salesGroup},
        {type: "Varchar", id: "Type", value: "Export"},
        {type: "DATETIME", id: "DateFrom", value: dateFrom},
        {type: "DATETIME", id: "DateTo", value: dateTo},
        {type: "DATETIME", id: "Date", value: dateDebt},
        {type: "Varchar", id: "MethodID", value: methodID},
        {type: "INT", id: "Mode", value: 1},
    ];

    //RENDER
    return (
        <React.Fragment>
            <Row>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}> {Config.lang('CRM_Nhom_kinh_doanh')}</label>
                    <span className={'text-red text-bold'}>*</span>
                </Col>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}> {Config.lang("CRM_Nhan_vien_kinh_doanh")}</label>
                </Col>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}> {Config.lang('CRM_Khach_hang')}</label>
                </Col>
            </Row>
            <FormGroup>
                <Col lg={4} md={4}>
                    <DropDownSaleGroup placeholder={Config.lang('CRM_Nhom_kinh_doanh')}
                                       onChange={onChangeSalesGroup}
                                       value={salesGroup}
                                       loadSuccess={_onInitSalesGroup}

                    />
                </Col>
                <Col lg={4} md={4}>
                    <DropDownSalePerson showClearButton={true}
                                        onChange={onChangeSalesPerson}
                                        groupSalesID={salesGroup}
                                        placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                        value={salesPerson}
                    />
                </Col>
                <Col lg={4} md={4}>
                    <DropDownCustomer salesPersonID={salesPerson}
                                      groupSalesID={salesGroup}
                                      value={customer}
                                      placeHolder={Config.lang('CRM_Khach_hang')}
                                      onChangeCustomer={onChangeCustomer}/>
                </Col>
            </FormGroup>
            <Row>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}> {Config.lang('CRM_Thoi_gian')}</label>
                </Col>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}> {Config.lang('CRM_Ngay_tu')}</label>
                </Col>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}>{Config.lang('CRM_Ngay_den')}</label>
                </Col>
            </Row>
            <FormGroup>
                <Col lg={4} md={4}>
                    <DropDownTypeTime
                        listType={"W18F4040_TypeTime"}
                        placeholder={""}
                        onChange={onChangeTime}
                        onLoad={(data) => onChangeTime(data, 'load')}
                    />
                </Col>
                <Col lg={4} md={4}>
                    <DateBox width={'100%'}
                             dateSerializationFormat={'yyyy-MM-dd'}
                             displayFormat={'dd/MM/y'}
                             openOnFieldClick={true}
                             useMaskBehavior={true}
                             onValueChanged={onChangeDateFrom}
                             value={dateFrom}
                             showClearButton={true}
                    >
                        <Validator>
                            <CustomRule validationCallback={(e) => validateData(e, dateTo, 1)}/>
                        </Validator>
                    </DateBox>
                </Col>
                <Col lg={4} md={4}>
                    <DateBox width={'100%'}
                             openOnFieldClick={true}
                             displayFormat={'dd/MM/y'}
                             dateSerializationFormat={'yyyy-MM-dd'}
                             onValueChanged={onChangeDateTo}
                             value={dateTo}
                             showClearButton={true}
                    >
                        <Validator>
                            <CustomRule validationCallback={(e) => validateData(e, dateFrom, 2)}/>
                        </Validator>
                    </DateBox>
                </Col>
            </FormGroup>
            <Row>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}>{Config.lang("CRM_Phuong_phap_tinh_tuoi_no")}</label>
                    <span className={'text-red text-bold'}>*</span>
                </Col>
                <Col lg={4} md={4}>
                    <label className={'text-muted'}>{Config.lang("CRM_Ngay_tinh_no")}</label>
                    <span className={'text-red text-bold'}>*</span>
                </Col>
            </Row>
            <FormGroup>
                <Col lg={4} md={4}>
                    <Combo
                        showHeader={false}
                        showClearButton={true}
                        placeholder={Config.lang('CRM_Phuong_phap_tinh_tuoi_no')}
                        valueExpr="MethodID"
                        value={methodID}
                        dataSource={dataDebtMethod}
                        required={true}
                        displayExpr="MethodID"
                        onActionWhenSelectChange={onChangeDebtMethod}
                    >
                        <Column dataField={'MethodID'}
                                caption={''}
                                dataType={'string'}/>
                        />

                    </Combo>
                </Col>
                <Col lg={4} md={4}>
                    <DateBox width={'100%'}
                             displayFormat={'dd/MM/y'}
                             dateSerializationFormat={'yyyy-MM-dd'}
                             openOnFieldClick={true}
                             useMaskBehavior={true}
                             value={dateDebt}
                             placeholder={Config.lang('CRM_Ngay_tinh_no')}
                             onValueChanged={onChangeDateDebt}
                             showClearButton={true}
                    >
                        <Validator>
                            <RequiredRule message={Config.lang("CRM_Khong_duoc_de_trong")}/>
                        </Validator>
                    </DateBox>
                </Col>
                <Col lg={4} md={4}>
                    <ButtonView name={Config.lang('CRM_Xem')} onClick={onView}/>
                    <ButtonExport name={Config.lang('Xuat_du_lieu')}
                                  transTypeID={'ExportQuotation'}
                                  reportTypeID={"W18F4050"}
                                  param={paramPrint}
                                  className={'mgl5'}/>
                </Col>
            </FormGroup>
        </React.Fragment>
    )
});


const TreeListComp = React.memo((props) => {
    //----------------------DECLARE----------------------
    const {dataSource, onAddChild, dynamicCaption} = props;
    //----------------------ENDDECLARE----------------------

    //----------------------METHOD----------------------
    const _onAddChild = (e) => {
        const {column} = e;
        if (column.dataField === 'ObjectID') {
            onAddChild && onAddChild(e)
        }
    };

    //----------------------ENDMETHOD----------------------
    return (
        <TreeList
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr={'ObjectID'}
            parentIdExpr={'ObjectParentID'}
            dataSource={dataSource}
            onCellClick={_onAddChild}
            height={dataSource.length > 15 ? 450 : 'auto'}// khi datasource !== 15 thì height fit nội dung
            virtualModeEnabled={true}
            hasItemsExpr={'HasChild'}
            showRowLines={true}
        >
            <Column dataField={'ObjectID'}
                    caption={Config.lang("CRM_Ma")}
                    dataType={'string'}
                    fixed={window.innerWidth > 600}
            />
            <Column dataField={'ObjectNameU'}
                    allowEditing={false}
                    width={350}
                    caption={Config.lang("CRM_Ten")}
                    fixed={window.innerWidth > 600}
            />
            {
                dynamicCaption.map((value, index) => {
                    return (
                        <Column key={`${value.FieldName}-${index}`}
                                dataField={value.FieldName}
                                caption={value.Caption}
                                format={'#,##0'}
                                alignment={'right'}
                                dataType={'string'}
                        />
                    )
                })
            }
            <Column dataField={'RateBadDebt'}
                    allowEditing={false}
                    caption={`${Config.lang('CRM_Ty_le_qua_han')} %`}
            />
        </TreeList>
    )
});


UI.propTypes = {
    onChangeDateFrom: PropTypes.func,
    onChangeDateTo: PropTypes.func,
    onChangeTime: PropTypes.func,
    onChangeSalesGroup: PropTypes.func,
    onChangeSalesPerson: PropTypes.func,

    salesPerson: PropTypes.string,
    salesGroup: PropTypes.string,
    dateTo: PropTypes.string,
    dateFrom: PropTypes.string,
};

export default connect(
    (state) => ({
        dataDebtMethod: state.w18f4050.dataDebtMethod
    }),
    (dispatch) => ({
        action: bindActionCreators(action, dispatch),
        userActions: bindActionCreators(userActions, dispatch),

    })
)(Container)
