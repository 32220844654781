/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 12/11/2019
 */

import React, {useCallback} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from "../../config";
import {Col, Form, FormGroup, Collapse} from "react-bootstrap";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import DropDownCustomer from "../common/dropdown/dropdown-customer";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import {DateBox, Validator} from 'devextreme-react';
import {Column} from 'devextreme-react/data-grid';
import * as action from "../../actions/w18/w18F4040/w18F4040-actions";
import ButtonView from "../common/button/button-view";
import {TreeList} from "devextreme-react/tree-list";
import PropTypes from "prop-types";
import moment from "moment";
import * as userActions from "../../actions/user-actions";
import {CustomRule} from "devextreme-react/validator";

export class Container extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateTo: null,
            salesGroup: '',
            salesPerson: '',
            customer: '', // gắn giá trị lên dropdown
            dataSource: [],
            time: null,
            objectID: '',
            showFilterCondition: false
        };

        this.type = '';
        this.customer = ''; // không gắn giá lên dropdown
        this.initDefault = false;
    }

    //----------------------METHOD----------------------
    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, "D17F4041");
    }

    loadGrid = (mode) => {
        //mode = init tao lưới lần đầu, mode ='refresh'  mới ngược lại cộng thêm
        const {customer, dateFrom, dateTo, salesPerson, salesGroup} = this.state;
        const {type} = this;
        let fObject, fType;
        if (customer) {
            fObject = customer; //su dung this.customer de khogn set gia tri cho dropdown
            fType = 'Company';
        } else if (!customer && salesPerson) {
            fObject = salesPerson;
            fType = 'SalesPerson';
        } else {
            fObject = mode === 'init' ? null : salesGroup;
            fType = 'GroupSales';
        }
        const param = {
            UserID: Config.profile.UserID || "",
            Language: Config.language || '84',
            ObjectID: mode ? fObject : this.customer,
            Type: !mode ? type : fType,
            DateFrom: dateFrom,
            DateTo: dateTo,
            Mode: mode ? 1 : 0

        };

        this.props.action.loadGrid(param, (error, data) => {
            this.setState({
                dataSource: !mode ? [...this.state.dataSource, ...data] : data
            })
        })
    };

    onShowFilterCondition = () => {
        this.setState({
            showFilterCondition: !this.state.showFilterCondition
        })
    }

    componentDidMount = () => {
        this.loadPermission();
        this.getDecimal();

    };

    setDateFrom = (e) => {
        this.setState({
            dateFrom: e,
        })
    };

    setDateTo = (e) => {
        this.setState({
            dateTo: e,
        })
    };

    setTime = (data) => {
        this.setState({
            time: data.ID,
            dateFrom: data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null,
            dateTo: data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null
        })
    };

    setSalesGroup = (e) => {
        this.setState({
            salesGroup: e,
            customer: '',
        })
    };

    setCustomer = (e) => {
        this.setState({
            customer: e
        })
    };

    setSalesPerson = (e) => {
        this.setState({
            salesPerson: e,
        })
    };

    setDefaultSalesGroup = (e) => {
        const IsRoot = e.find(value => value.IsRoot === 1);

        this.setState({
            salesGroup: IsRoot.GroupSalesID
        })

    };

    onAddChild = (e) => {
        const {data} = e.row;// su dung bien vi state se render lai form va gan gia tri len dropdown

        if (!(e.component.getNodeByKey(e.value).children.length > 0)) {
            this.type = data.Type;
            this.customer = e.key;
            this.loadGrid()
        }
    };

    getDecimal = () => {
        this.props.action.getDecimal((error) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('CRM_Loi_chua_xac_dinh'));
                return false;
            }
        })
    };

    onView = () => {
        this.loadGrid('refresh')
    };

    //----------------------ENDMETHOD----------------------

    componentDidUpdate(prevProps, prevState, snapshot) {
        //sau khi default dòng đầu tiên của dropdown salesgroup thì load lại form
        const {salesGroup, dateFrom, dateTo} = this.state;
        if (!this.initDefault && salesGroup && dateFrom && dateTo) {
            this.loadGrid('init');
            this.initDefault = true
        }
    }

    render() {
        const {dateFrom, dateTo, salesPerson, salesGroup, customer, dataSource, showFilterCondition} = this.state;
        const {dataDecimal} = this.props;

        return (
            <UI onChangeDateFrom={this.setDateFrom}
                onChangeDateTo={this.setDateTo}
                onChangeTime={this.setTime}
                onChangeSalesGroup={this.setSalesGroup}
                onInitSalesGroup={this.setDefaultSalesGroup}
                onChangeSalesPerson={this.setSalesPerson}
                onChangeCustomer={this.setCustomer}
                onAddChild={this.onAddChild}
                onView={this.onView}
                onShowFilterCondition={this.onShowFilterCondition}

                dataDecimal={dataDecimal}
                dateFrom={dateFrom}
                dateTo={dateTo}
                salesPerson={salesPerson}
                salesGroup={salesGroup}
                customer={customer}
                dataSource={dataSource}
                showFilterCondition={showFilterCondition}
            />
        )
    }
}

const UI = React.memo((props) => {
    //----------------------DECLARE----------------------
    const paramFilter = {
        onChangeDateFrom: props.onChangeDateFrom,
        onChangeDateTo: props.onChangeDateTo,
        onChangeSalesGroup: props.onChangeSalesGroup,
        onChangeTime: props.onChangeTime,
        onChangeSalesPerson: props.onChangeSalesPerson,
        onChangeCustomer: props.onChangeCustomer,
        salesGroup: props.salesGroup,
        salesPerson: props.salesPerson,
        customer: props.customer,
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
        onInitSalesGroup: props.onInitSalesGroup,
    };

    const paramTree = {
        dataSource: props.dataSource,
        onAddChild: props.onAddChild,
        dataDecimal: props.dataDecimal && props.dataDecimal.D07_QuantityDecimals
    };
    //----------------------ENDDECLARE----------------------

    return (
        <div id={'W18F4040'} className="page-container">
            <div className={'mgb5'} style={{display: 'flex', flexWrap: 'wrap'}}>
                <div className="grid-title">{Config.lang("CRM_Bao_cao_khach_hang_theo_ma_hang")}</div>
                <div className={'mgr5'} style={{marginTop: '-3px', marginLeft: 'auto', cursor: 'pointer'}}>
                    <i style={{
                        transition: 'transform .5s',
                        color: `${props.showFilterCondition ? 'rgb(66, 148, 251)' : ''}`,
                        transform: `${props.showFilterCondition ? 'rotate(177deg)' : ''}`
                    }} onClick={props.onShowFilterCondition}
                        className={'fa-lg fas fa-chevron-circle-down mgt10'}
                    />
                </div>
            </div>
            <Form horizontal={true}>
                <Collapse in={props.showFilterCondition}>
                    <div>
                        <FilterCondition {...paramFilter}  />
                        <FormGroup>
                            <Col lg={12} md={12}>
                                <ButtonView onClick={props.onView}
                                            domProps={{style: {float: 'right'}}}
                                            name={Config.lang('CRM_Xem')}/>
                            </Col>
                        </FormGroup>
                    </div>
                </Collapse>
                <FormGroup>
                    <Col lg={12}>
                        <TreeListComp {...paramTree}/>
                    </Col>
                </FormGroup>
            </Form>
        </div>
    )
});


const FilterCondition = React.memo((props) => {
    //----------------------DECLARE----------------------
    // const contextValue = useContext(Context) // ;
    const {salesGroup, salesPerson, customer, dateFrom, dateTo} = props;
    //----------------------ENDDECLARE----------------------


    //----------------------METHOD----------------------
    const onChangeDateFrom = useCallback((e) => {
        props.onChangeDateFrom && props.onChangeDateFrom(e.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom]);

    const onChangeDateTo = useCallback((e) => {
        props.onChangeDateTo && props.onChangeDateTo(e.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTo]);

    const onChangeSalesGroup = useCallback((e) => {
        props.onChangeSalesGroup && props.onChangeSalesGroup(e)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesGroup]);

    const onChangeTime = (data, mode) => {
        props.onChangeTime && props.onChangeTime(data, mode);
    };

    const onChangeSalesPerson = useCallback((e) => {
        props.onChangeSalesPerson && props.onChangeSalesPerson(e && e.SalesPersonID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesPerson]);

    const onChangeCustomer = useCallback((e) => {
        props.onChangeCustomer && props.onChangeCustomer(e);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, salesPerson, salesGroup]);

    const _onInitSalesGroup = (e) => {
        props.onInitSalesGroup && props.onInitSalesGroup(e);
    };

    const validateData = (e, compareValue, mode) => {
        e.rule.message = '';
        if (!e.value) {
            e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
            return false;
        }
        if (mode === 1) {
            e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
            return e.value <= compareValue;
        } else {
            e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
            return e.value >= compareValue;
        }
    };


    //----------------------ENDMETHOD----------------------
    //RENDER
    return (
        <React.Fragment>
            <FormGroup>
                <Col lg={4} md={4}>
                    <DropDownSaleGroup placeholder={"Nhóm kinh doanh"}
                                       onChange={onChangeSalesGroup}
                                       value={salesGroup}
                                       loadSuccess={_onInitSalesGroup}

                    />
                </Col>
                <Col lg={4} md={4}>
                    <DropDownSalePerson showClearButton={true}
                                        onChange={onChangeSalesPerson}
                                        groupSalesID={salesGroup}
                                        placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                        value={salesPerson}
                    />
                </Col>
                <Col lg={4} md={4}>
                    <DropDownCustomer salesPersonID={salesPerson}
                                      groupSalesID={salesGroup}
                                      value={customer}
                                      placeHolder={Config.lang('CRM_Khach_hang')}
                                      onChangeCustomer={onChangeCustomer}/>
                </Col>
            </FormGroup>
            <FormGroup>
                <Col lg={4} md={4}>
                    <DropDownTypeTime
                        listType={"W18F4040_TypeTime"}
                        placeholder={""}
                        onChange={onChangeTime}
                        onLoad={(data) => onChangeTime(data, 'load')}
                    />
                </Col>
                <Col lg={4} md={4}>
                    <DateBox width={'100%'}
                             dateSerializationFormat={'yyyy-MM-dd'}
                             displayFormat={'dd/MM/y'}
                             openOnFieldClick={true}
                             useMaskBehavior={true}
                             onValueChanged={onChangeDateFrom}
                             value={dateFrom}
                             showClearButton={true}
                    >
                        <Validator>
                            <CustomRule validationCallback={(e) => validateData(e, dateTo, 1)}/>
                        </Validator>
                    </DateBox>
                </Col>
                <Col lg={4} md={4}>
                    <DateBox width={'100%'}
                             openOnFieldClick={true}
                             displayFormat={'dd/MM/y'}
                             dateSerializationFormat={'yyyy-MM-dd'}
                             onValueChanged={onChangeDateTo}
                             value={dateTo}
                             showClearButton={true}
                    >
                        <Validator>
                            <CustomRule validationCallback={(e) => validateData(e, dateFrom, 2)}/>
                        </Validator>
                    </DateBox>
                </Col>
            </FormGroup>
        </React.Fragment>
    )
});


const TreeListComp = React.memo((props) => {
    //----------------------DECLARE----------------------
    // const contextValue = useContext(Context);
    const {dataSource, onAddChild, dataDecimal} = props;
    //----------------------ENDDECLARE----------------------


    //----------------------METHOD----------------------
    const _onAddChild = (e) => {
        if (e.columnIndex === 0) {
            onAddChild && onAddChild(e)

        }
    };

    //----------------------ENDMETHOD----------------------


    //----------------------RENDER----------------------
    const renderQuantity = (e) => {
        const {data} = e.row;
        const {Type} = data;

        if (Type === 'Inventory') {
            return e.value.toFixed(dataDecimal)
        }
    };

    //----------------------ENDRENDER----------------------

    return (
        <TreeList
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr={'ObjectID'}
            parentIdExpr={'ObjectParentID'}
            dataSource={dataSource}
            onCellClick={_onAddChild}
            virtualModeEnabled={true}
            hasItemsExpr={'HasChild'}
            showRowLines={true}
        >
            <Column dataField={'ObjectID'}
                    caption={Config.lang("CRM_Ma")}
                    dataType={'string'}
            />
            <Column dataField={'ObjectNameU'}
                    allowEditing={false}
                    width={350}
                    caption={Config.lang("CRM_Ten")}
            />
            <Column dataField={'Quantity'}
                    alignment={'right'}
                    format={'#,##0'}
                    width={100}
                    caption={Config.lang("CRM_So_luong")}
                    cellRender={renderQuantity}
            />
            <Column dataField={'Amount'}
                    alignment={'right'}
                    width={150}
                    format={'#,##0'}
                    caption={Config.lang("CRM_Tien_ban")}
            />
            <Column dataField={'VAT'}
                    alignment={'right'}
                    caption={Config.lang("CRM_Tien_thue")}
                    width={150}
                    format={'#,##0'}
            />
            <Column dataField={'Revenue'}
                    caption={Config.lang("CRM_Doanh_so")}
                    width={150}
                    alignment={'right'}
                    format={'#,##0'}/>
        </TreeList>
    )
});


UI.propTypes = {
    onChangeDateFrom: PropTypes.func,
    onChangeDateTo: PropTypes.func,
    onChangeTime: PropTypes.func,
    onChangeSalesGroup: PropTypes.func,
    onChangeSalesPerson: PropTypes.func,

    salesPerson: PropTypes.string,
    salesGroup: PropTypes.string,
    dateTo: PropTypes.string,
    dateFrom: PropTypes.string,
};

export default connect(
    (state) => ({
        dataDecimal: state.w18f4040.dataDecimal
    }),
    (dispatch) => ({
        action: bindActionCreators(action, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(Container)
